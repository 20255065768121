import { SchedulingQuestion, SchedulingSection } from '@mmx/shared'

const findQuestionInQuestions = (questions: SchedulingQuestion[], questionId: string): SchedulingQuestion | undefined => {
  for (const q of questions) {
    if (q.id === questionId) {
      return q
    }

    if (!q.questions?.length && !(q as any).ifQuestions?.length) {
      continue
    }

    const subQ = findQuestionInQuestions(q.questions || (q as any).ifQuestions, questionId)

    if (subQ) {
      return subQ
    }
  }

  return undefined
}

export const findQuestion = (sections: SchedulingSection[], questionId: string): SchedulingQuestion | undefined => {
  let question: SchedulingQuestion | undefined

  for (const section of sections) {
    question = findQuestionInQuestions(section.questions || [], questionId)

    if (question) {
      break
    }
  }

  return question
}
