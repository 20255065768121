import { CRUD, CrudModel } from '../../classes'
import { BaseModelData } from '../base-model-data'
import { SchedulingQuestion, SchedulingQuestionData, SchedulingQuestionRestrictions } from './scheduling-question'

export interface SchedulingSectionData extends BaseModelData {
  label: string
  order: number
  questions?: SchedulingQuestionData[]
  requirements?: SchedulingQuestionRestrictions
}

@CRUD({
  base: '/crud/scheduling-sections',
})
export class SchedulingSection extends CrudModel implements SchedulingSectionData {
  label!: string
  order!: number
  questions?: SchedulingQuestion[]
  requirements?: SchedulingQuestionRestrictions

  constructor(data?: SchedulingSectionData) {
    super(data, true)

    if (data?.questions) {
      this.questions = data.questions.map((question) => new SchedulingQuestion(question))
    }
  }

  override toJSON() {
    return {
      ...super.toJSON(),
      label: this.label,
      order: this.order,
      requirements: this.requirements,
    }
  }
}
