import { CRUD, CrudModel } from '../../classes'
import { IQuestion, IQuestionOptions, IStopQuestionSettings, QuestionMeasurementType, QuestionType } from '../question/question'

export interface SchedulingQuestionRestrictions {
  services?: string[]
  facilities?: string[]
}

export interface SchedulingQuestionData extends IQuestion {
  requirements?: SchedulingQuestionRestrictions
}

@CRUD({
  base: '/crud/scheduling-questions',
})
export class SchedulingQuestion extends CrudModel implements SchedulingQuestionData {
  type!: QuestionType
  label!: string
  if?: boolean
  expectedAnswer?: boolean
  translate?: boolean
  measurementType?: QuestionMeasurementType
  required!: boolean
  favorite!: boolean
  leg?: string
  key?: string
  parent?: string
  hint?: string
  flex?: string
  options?: IQuestionOptions
  isDisabled?: boolean
  reference?: string
  hasDedicatedPage?: boolean
  sectionId!: string
  order!: number
  questions?: SchedulingQuestion[]
  requirements?: SchedulingQuestionRestrictions
  stopQuestionSettings?: IStopQuestionSettings

  constructor(data?: SchedulingQuestionData) {
    super(data, true)

    if (data?.questions) {
      this.questions = data.questions.map((question) => new SchedulingQuestion(question))
    }
  }

  override toJSON() {
    return {
      ...super.toJSON(),
      type: this.type,
      label: this.label,
      if: this.if,
      expectedAnswer: this.expectedAnswer,
      translate: this.translate,
      measurementType: this.measurementType,
      required: this.required,
      favorite: this.favorite,
      parent: this.parent,
      hint: this.hint,
      flex: this.flex,
      options: this.options,
      isDisabled: this.isDisabled,
      hasDedicatedPage: this.hasDedicatedPage,
      sectionId: this.sectionId,
      order: this.order,
      requirements: this.requirements,
    }
  }
}
