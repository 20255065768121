import { Injectable } from '@angular/core'
import { Router } from '@angular/router'

import { AuthService } from '../services/auth.service'

@Injectable()
export class TabletGuard {
  constructor(
    private authService: AuthService,
    private router: Router,
  ) { }

  canActivate(): boolean {
    const isTablet = this.authService.facilityTable

    if (!isTablet) {
      return true
    } else {
      this.router.navigate(['/login'])
      return false
    }
  }
}
