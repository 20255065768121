import * as _ from 'lodash'

import { BaseModel } from '../base-model'
import { ConsentData } from './consent-data'
import { ConsentFrequency } from './consent-frequency'
import { ConsentRequirements } from './consent-requirements'

export class Consent extends BaseModel {
  title: string
  titleLangMap?: { [key: string]: string }
  shortTitle?: string
  text: string
  textLangMap?: { [key: string]: string }
  required: boolean
  scrollRequired: boolean
  dedicatedPage: boolean
  frequency: ConsentFrequency
  acceptText?: string
  url?: string
  order?: number
  type?: string
  requirements?: ConsentRequirements

  constructor(data: ConsentData) {
    super(data)
    this.title = data.title || '' // todo check optional title in ConsentData
    this.shortTitle = data.shortTitle
    this.textLangMap = data.textLangMap ?? {}
    this.titleLangMap = data.titleLangMap ?? {}
    this.text = data.text || '' // todo check optional text in ConsentData
    this.required = data.required
    this.scrollRequired = data.scrollRequired
    this.dedicatedPage = data.dedicatedPage
    this.frequency = data.frequency
    this.acceptText = data.acceptText || ''
    this.url = data.url || ''
    this.order = data.order
    this.type = data.type || ''
    this.requirements = data.requirements || {}
  }

  override toJSON() {
    return _.extend(super.toJSON(), {
      title: this.title,
      shortTitle: this.shortTitle,
      text: this.text,
      titleLangMap: this.titleLangMap,
      textLangMap: this.textLangMap,
      required: this.required,
      scrollRequired: this.scrollRequired,
      dedicatedPage: this.dedicatedPage,
      frequency: this.frequency,
      acceptText: this.acceptText,
      url: this.url,
      order: this.order,
      type: this.type,
      requirements: this.requirements,
    })
  }
}
