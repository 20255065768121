import { AbstractControl, ValidatorFn } from '@angular/forms'

export function phoneValidator(): ValidatorFn {
  const phoneMatcherUI = /^\+1\s\(\d{3}\)\s\d{3}-\d{4}$/
  const phoneMatcherDB = /^\+1\d{10}$/
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (
      control.value &&
      control.value.length > 0 &&
      !phoneMatcherUI.test(control.value) &&
      !phoneMatcherDB.test(control.value)
    ) {
      return { phone: true }
    } else {
      return null
    }
  }
}
