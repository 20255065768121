import { BaseModel, ClinicEhr, ClinicModules, ClinicModulesPreferences } from '@mmx/shared'
import { cloneDeep } from 'lodash'

import { standardizePhone } from '../../utils/utils'
import { ClinicData } from './clinic-data'
import { ClinicOptions } from './clinic-options'
import { IClinicPaymentProvider } from './clinic-payment-provider'
import { ClinicProvider } from './clinic-provider'
import { ClinicSocialNetwork } from './clinic-social-network'
import { ClinicType } from './clinic-type'

export class Clinic extends BaseModel {
  id: string
  slug: string
  name: string
  email: string
  ehr: ClinicEhr
  phoneNumber: string
  languages: string[]
  twilioNumber: string
  image: string
  website: string
  paymentProvider: IClinicPaymentProvider
  pinCode: string
  type: ClinicType
  provider: ClinicProvider
  socialNetwork: ClinicSocialNetwork
  options: ClinicOptions
  modules: ClinicModules
  modulesPreferences: ClinicModulesPreferences
  settings: { [key: string]: any }

  constructor(data?: ClinicData) {
    super(data, true)

    if (!this.modules) {
      this.modules = { }
    }
    if (!this.modulesPreferences) {
      this.modulesPreferences = { }
    }
    if (!this.settings) {
      this.settings = { }
    }
    if (!this.options) {
      this.options = { }
    }
    if (!this.provider) {
      this.provider = { }
    }
    if (!this.socialNetwork) {
      this.socialNetwork = { }
    }
    if (!this.paymentProvider) {
      this.paymentProvider = { }
    }

    this.options.inPatientContact = true
  }

  toJSON() {
    return {
      id: this.id,
      name: this.name,
      slug: this.slug,
      pinCode: this.pinCode,
      phoneNumber: standardizePhone(this.phoneNumber),
      languages: this.languages,
      twilioNumber: standardizePhone(this.twilioNumber),
      website: this.website,
      provider: cloneDeep(this.provider),
      socialNetwork: cloneDeep(this.socialNetwork),
      paymentProvider: cloneDeep(this.paymentProvider),
      options: cloneDeep(this.options),
      modules: cloneDeep(this.modules),
      modulesPreferences: cloneDeep(this.modulesPreferences),
      settings: cloneDeep(this.settings),
    }
  }

  get url() {
    const hostname = location.hostname

    if (hostname === 'localhost') {
      // DEV
      return `${window.location.protocol}//${this.slug}:5554`
    } else {
      const clinicHostname = hostname.replace('patientpal', 'console.patientpal')
      return `${window.location.protocol}//${clinicHostname}`
    }
  }

  get isCLinicWhiteLabeled() {
    const whiteLabeledClinic = [ClinicType.EXPEDITOR, ClinicType.HEALTH_LOGIX]
    if (this.type) {
      return whiteLabeledClinic.includes(this.type)
    }
    return false
  }

  clone(): Clinic {
    return new Clinic(this.toJSON())
  }
}
