import { SelectOption } from '@mmx/shared'

export const ContactMethods: SelectOption<string>[] = [
  { value: '', viewValue: 'common.unspecified' },
  { value: 'email', viewValue: 'common.email' },
  { value: 'sms', viewValue: 'common.text-message' },
  { value: 'phone', viewValue: 'common.phone' },
  { value: 'cell', viewValue: 'patient-edit.phone-types.cell' },
  { value: 'home', viewValue: 'patient-edit.phone-types.home' },
  { value: 'work', viewValue: 'patient-edit.phone-types.work' },
  { value: 'blocked', viewValue: 'common.do-not-contact' },
]
