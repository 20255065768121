import { BaseModelData, CRUD, CrudModel, CUSTOM_FIELD_FOR } from '@mmx/shared'

export interface CustomFieldData extends BaseModelData {
  key?: string
  for?: CUSTOM_FIELD_FOR
  name?: string
  type?: string
  hint?: string
  integrationField?: string
  settings?: {
    dropdownOptions?: string[]
    dropdownMultiple?: boolean
  }
}

export enum CUSTOM_FIELD_TYPE {
  BOOLEAN = 'Boolean',
  STRING = 'String',
  NUMBER = 'Number',
  DATE = 'Date',
  DATETIME = 'DateTime',
  DROPDOWN = 'Dropdown',
}

@CRUD({
  base: '/crud/custom-fields',
})
export class CustomFieldModel extends CrudModel {
  key?: string
  for?: CUSTOM_FIELD_FOR
  name?: string
  type?: string
  hint?: string
  integrationField?: string
  settings?: {
    dropdownOptions?: string[]
    dropdownMultiple?: boolean
  }

  constructor(data?: CustomFieldData) {
    super(data, true)

    if (data?.settings) {
      this.settings = data.settings
    }
  }

  get url(): string {
    return `/settings/custom-fields/${this.id}`
  }

  override toJSON() {
    return Object.assign(super.toJSON(), {
      key: this.key,
      for: this.for,
      name: this.name,
      type: this.type,
      hint: this.hint,
      integrationField: this.integrationField,
      settings: this.settings,
    })
  }
}
