import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse, HttpResponseBase,
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { MatSnackBar } from '@angular/material/snack-bar'
import { Observable, of, tap, throwError } from 'rxjs'

import {
  ACCEPTED,
  BAD_REQUEST,
  CREATED,
  FORBIDDEN, GATEWAY_TIMEOUT,
  INTERNAL_SERVER_ERROR,
  NETWORK_FAILURE,
  NOT_FOUND,
  OK,
  SESSION_TIMEOUT,
  UNAUTHENTICATED,
} from '../../shared/data/http-status-codes'
import { AuthService } from './auth.service'

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private snackBar: MatSnackBar,
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // ignore login contains "auth", since they'll likely be logging in
    if (req.url.match(/(auth|login|amazonaws)/)) {
      return next.handle(req)
    }

    // Clone the request to add the new header.
    const token = this.authService.sessionToken

    if (token) {
      const headers = req.headers
        .set('Authorization', token)
        // .set('Pragma', 'no-cache')
        // .set('Cache-Control', 'no-cache')
        // .set('If-Modified-Since', 'Tue, 01 Jan 1980 1:00:00 GMT')

      const authReq = req.clone({ headers })

      // Pass on the cloned request instead of the original request.
      return next.handle(authReq)
        .pipe(
          tap((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
              const headerNames = ['authorization', 'x-amzn-remapped-authorization', 'maps.googleapis.com']
              const newToken = headerNames.reduce((acc: string, name: string) => event.headers.get(name) || acc, null)

              if (newToken) {
              // update and ratchet session token automatically
                this.authService.sessionToken = newToken
              }
            }
          }, (err: any) => {
            this.handleResp(err)
          }),
        )
    } else {
      return next.handle(req)
    }
  }

  private handleResp(rev: HttpResponseBase): Observable<any> {
    switch (rev.status) {
      case OK:
      case CREATED:
      case ACCEPTED:
        break
      case UNAUTHENTICATED:
        this.authService.logout()
        break
      case BAD_REQUEST:
      case NOT_FOUND:
      case FORBIDDEN:
        break
      case SESSION_TIMEOUT:
      case INTERNAL_SERVER_ERROR:
      case NETWORK_FAILURE:
      case GATEWAY_TIMEOUT:
        this.snackBar.open(
          'It appears something went wrong, please try reloading the page.',
          null,
          { duration: 3000, panelClass: 'error' })
        break
      default:
        if (rev instanceof HttpErrorResponse) {
          return throwError(() => rev)
        }
        break
    }
    return of(rev)
  }
}
