import { SelectOption } from '@mmx/shared'

/**
 * When adding new languages, also update the languageName language pipe
 */
export const Languages: SelectOption<string>[] = [
  { viewValue: 'English', value: 'en' },
  { viewValue: 'Spanish', value: 'es' },
  { viewValue: 'Cantonese', value: 'zh-hk' },
  { viewValue: 'Arabic', value: 'ar' },
  { viewValue: 'Chinese', value: 'zh' },
  { viewValue: 'Farsi', value: 'fa' },
  { viewValue: 'French', value: 'fr' },
  { viewValue: 'German', value: 'de' },
  { viewValue: 'Hindi', value: 'hi' },
  { viewValue: 'Italian', value: 'it' },
  { viewValue: 'Korean', value: 'ko' },
  { viewValue: 'Polish', value: 'pl' },
  { viewValue: 'Russian', value: 'ru' },
  { viewValue: 'Tagalog', value: 'tl' },
  { viewValue: 'Vietnamese', value: 'vi' },
]
