import { BaseModel } from '../model/base-model'

export interface CrudOptions {
  /**
   * Base path for RESTFUL CRUD requests.
   *
   * Example: /patients
   */
  base: string
}

/**
 * Models that implement CRUD can be used with the CrudService.
 */
export class CrudModel extends BaseModel {
  static crudOptions?: CrudOptions

  static getObjectEndpoint(id: string): string {
    const base = this.crudOptions!.base
    return `${base}/${id}`
  }

  getObjectEndpoint(): string {
    const modelClass: typeof CrudModel = this.constructor as any
    return modelClass.getObjectEndpoint(this.id)
  }
}

/**
 * Class decorator for CRUD models
 */
export function CRUD(crudOptions: CrudOptions) {
  return <U extends typeof CrudModel>(modelClass: U) => {
    modelClass.crudOptions = crudOptions
    return modelClass
  }
}
