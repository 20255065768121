import { AbstractControl, ValidatorFn } from '@angular/forms'

export function validateWeekdays(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const weekdays = control.value
    let isValid = false

    // Check if every key in the weekdays object has an array with at least one value
    for (const day in weekdays) {
      if (weekdays.hasOwnProperty(day)) {
        if (weekdays[day].length) {
          isValid = true
          break
        }
      }
    }

    return isValid ? null : { invalidWeekdays: { value: control.value } }
  }
}
