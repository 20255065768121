import { BaseModel } from '../base-model'
import { BaseModelData } from '../base-model-data'

export interface InsuranceProviderConfigurationData extends BaseModelData {
  payerId: string
  type: InsuranceProviderConfiguration.TYPE
  subtypeId: InsuranceProviderConfiguration.SUBTYPE_ID
  source: InsuranceProviderConfiguration.SOURCE
  elements: InsuranceProviderConfiguration.Elements
}

export interface InsuranceProviderConfigurationSubtypeData {
  type: InsuranceProviderConfiguration.TYPE
  subtypeId: InsuranceProviderConfiguration.SUBTYPE_ID
  subtypeValue: string
  payerId: string
  payerName: string
}

export class InsuranceProviderConfigurationModel extends BaseModel {
  payerId: string
  type: InsuranceProviderConfiguration.TYPE
  subtypeId: InsuranceProviderConfiguration.SUBTYPE_ID
  source: InsuranceProviderConfiguration.SOURCE
  elements: InsuranceProviderConfiguration.Elements

  constructor(data: InsuranceProviderConfigurationData) {
    super(data)

    this.payerId = data.payerId
    this.type = data.type
    this.subtypeId = data.subtypeId
    this.source = data.source
    this.elements = data.elements
  }
}

export namespace InsuranceProviderConfiguration {
  export enum TYPE {
    SERVICE_REVIEWS = 'service-reviews',
    SERVICE_REVIEWS_INQUIRY = 'service-reviews-inquiry',
    COVERAGES = '270',
  }

  export enum SUBTYPE_ID {
    SPECIALITY_CARE = 'SP',
    /**
     * Inpatient Authorizations / Admission Review
     */
    ADMISSION = 'AR',
    /**
     * Outpatient authorizations
     */
    HEALTH_SERVICES = 'HS',
    /**
     * Specialty Care Review/referral
     */
    REFERRALS = 'SC',
  }

  export enum ROLE_CODE {
    FACILITY = 'FA',
  }

  export enum SOURCE {
    AVAILITY = 'availity',
  }

  export enum ElementType {
    UNSUPPORTED = 'Unsupported',
    BOOLEAN = 'Boolean',
    COLLECTION = 'Collection',
    DATE = 'Date',
    ENUMERATION = 'Enumeration',
    INFORMATION = 'Information',
    SECTION = 'Section',
    TEXT = 'Text',
    NUMBER = 'Number',
    OBJECT = 'Object',
    OBJECT_ARRAY = 'ObjectArray',
  }

  export type Elements = Record<string, InsuranceProviderConfiguration.Element>

  export interface ElementWhenCondition {
    equalTo?: any
    containedIn?: any[]
    greaterThan?: any
    lessThan?: any
    greaterEqual?: any
    lessEqual?: any
    maxLength?: any
    pattern?: any
    values: any
  }

  export type Conditions = Record<string, {
    equalTo?: any
    containedIn?: any
    greaterThan?: any
    lessThan?: any
    greaterEqual?: any
    lessEqual?: any
    maxLength?: any
    pattern?: any
    values: any
  }>

  export interface ObjectType {
    label: string
    minInstances: number
    maxInstances: number
    required: boolean
    allowedWhen: ElementWhenCondition
    notAllowedWhen: ElementWhenCondition
    requiredWhen: ElementWhenCondition
    notRequiredWhen: ElementWhenCondition
    fieldValues: ElementWhenCondition
  }

  export interface Element {
    /**
     * The type of element. Generally maps to type of UI element.
     * Unsupported is not show.
     * Boolean is a checkmark.
     * Collection is a searchable REST backed drop down.
     * Date is a date picker.
     * Enumeration is a searchable inline drop down.
     * Information is just text.
     * Section is a grouper or container with text.
     * Text is a standard text box.
     * Number is generally a text box, but serialized as a numeric.
     * Object is grouping like section but with additional metadata.
     * ObjectArray is a repeating grouping with additional metadata.
     */
    type: ElementType
    label?: string
    order: number
    helpTopicId?: number
    /**
     * Child elements
     */
    elements?: Record<string, Element>
    errorMessage?: string
    /**
     * Number of times an item can repeat.
     */
    maxRepeats?: number
    allowed: boolean
    required?: boolean
    /**
     * A list of information.
     */
    information?: string[]
    /**
     * A list of groups.
     */
    groups?: string
    /**
     * Item repeats indicator.
     */
    repeats?: boolean
    hidden?: boolean
    /**
     * Minimum number of repetitions.
     */
    minRepeats?: number
    defaultValue?: any
    values?: any[]
    valuesWhen?: ElementWhenCondition[] | ElementWhenCondition
    /**
     * Minimum date.
     */
    min?: string
    /**
     * Maximum date.
     */
    max?: string
    pattern?: string
    maxLength?: number
    minLength?: number
    maxLengthWhen?: ElementWhenCondition[] | ElementWhenCondition
    patternWhen?: ElementWhenCondition[] | ElementWhenCondition
    mode?: 'DropDown' | 'RadioGroup'
    allowedWhen?: ElementWhenCondition[] | ElementWhenCondition
    notAllowedWhen?: ElementWhenCondition[] | ElementWhenCondition
    requiredWhen?: ElementWhenCondition[] | ElementWhenCondition
    notRequiredWhen?: ElementWhenCondition[] | ElementWhenCondition
    /**
     * Object array item type prototype definitions.
     */
    objectTypes?: Record<string, ObjectType>
  }
}
