import { Injectable } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { Clinic } from 'app/shared/models'
import { uniq } from 'lodash'
import * as moment from 'moment'
import { BehaviorSubject, combineLatest, Observable } from 'rxjs'
import { take } from 'rxjs/operators'

import { ClinicService } from './clinic.service'
import { OfflineService } from './offline.service'

const LANGUAGE_STORAGE_CODE = 'ppc-language'

@Injectable({
  providedIn: 'root',
})
export class I18nService {
  public onLocaleChange: Observable<string>
  public availableLangs: string[] = ['en']
  public currentLang: string
  public localeSubject: BehaviorSubject<string> = new BehaviorSubject('en')

  private loadedTranslations: { [key: string]: boolean } = { }
  private clinic: Clinic

  get languageCode() {
    return localStorage.getItem(LANGUAGE_STORAGE_CODE)
  }

  set languageCode(value: string) {
    localStorage.setItem(LANGUAGE_STORAGE_CODE, value)
  }

  constructor(
    private clinicService: ClinicService,
    private translateService: TranslateService,
    private offline: OfflineService,
  ) {
    this.onLocaleChange = this.localeSubject.asObservable()
    this.clinic = this.clinicService.clinic
    if (this.clinic) {
      this.onClinicChange()
    }

    this.clinicService.changes.subscribe((clinic) => {
      if (clinic && this.clinic?.id !== clinic.id) {
        this.clinic = clinic
        this.onClinicChange()
      }
    })
  }

  setLang(lang: string) {
    const setLang = () => {
      this.currentLang = lang
      this.translateService.use(lang)
      this.languageCode = lang
      moment.locale(lang)
      this.localeSubject.next(lang)
    }

    if (this.loadedTranslations[lang]) {
      setLang()
    } else {
      combineLatest([
        this.offline.untilSuccess(() => this.clinicService.getTranslations(lang)),
        this.offline.untilSuccess(() => this.translateService.getTranslation(lang).pipe(take(1))),
      ]).subscribe(([clinicTranslations, staticTranslations]) => {
        this.translateService.setTranslation(lang, {
          ...staticTranslations,
          ...clinicTranslations.data,
        }, true)
        this.loadedTranslations[lang] = true
        setLang()
      })
    }
  }

  private onClinicChange() {
    this.setDefaultLanguage()
    this.setLang(this.currentLang)
  }

  private setDefaultLanguage() {
    if (this.clinic.languages?.length > 1) {
      this.availableLangs = uniq(this.availableLangs.concat(this.clinic.languages))
    }

    if (this.languageCode) {
      // if we have a preferred language set on local storage, use that
      this.currentLang = this.languageCode
    } else if (navigator.language !== 'en' && this.availableLangs.includes(navigator.language)) {
      // if the device's language is set to a language the clinic supports, use that
      this.currentLang = navigator.language
    } else {
      // fallback to English as the language
      this.currentLang = 'en'
    }

    this.languageCode = this.currentLang
  }
}
