import { AddressSchema } from '../../schema'
import { BaseModel } from '../base-model'
import { PaymentProvider } from '../clinic'
import { CardBrand } from './card-brand'
import { CardData } from './card-data'

export class Card extends BaseModel {
  // this model is not an exact match of the backend card model, because
  // we actually load the card data from Stripe
  lastFourDigits!: number
  name?: string
  brand?: CardBrand
  funding?: string
  expirationMonth!: number
  expirationYear!: number
  address!: AddressSchema
  provider?: PaymentProvider

  constructor(data: CardData) {
    super(data, true)
  }

  get expiryDate() {
    return `${this.expirationMonth}/${this.expirationYear}`
  }
}
