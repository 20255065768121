import { Injectable } from '@angular/core'
import { NavigationStart, Router } from '@angular/router'
import {
  distinctUntilChanged,
  filter,
  map,
  Observable,
  ReplaySubject,
  takeUntil,
} from 'rxjs'

export type LoadingTuple = [string, string, boolean]

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  public loading = new ReplaySubject<LoadingTuple>(1)

  constructor(private router: Router) {}

  start(key: string, token?: string): void {
    this.loading.next([key, token, true])
  }

  stop(key: string, token?: string): void {
    this.loading.next([key, token, false])
  }

  isLoading(path: string, token?: string): Observable<boolean> {
    return this.loading.pipe(
      filter((tuple) => tuple[0].startsWith(path)),
      filter((tuple) => (token ? tuple[1] === token : true)),
      map((tuple) => tuple[2]),
      distinctUntilChanged(),
      takeUntil(
        this.router.events.pipe(
          filter((event) => event instanceof NavigationStart),
        ),
      ),
    )
  }
}
