import { BaseModel, BaseModelData, Facility, FacilityData } from '@mmx/shared'
import * as _ from 'lodash'
import * as moment from 'moment-timezone'

import { COLOR } from '../data/colors'
import { Patient, PatientData } from './patient.model'
import { ProductData, ProductModel } from './product.model'
import { EstimateSchema } from './schemas/estimate.schema'
import { TransactionData, TransactionModel } from './transaction.model'

const timeFormat = 'LT' // 'h:mm A'

export interface AppointmentInsurances {
  clinicCarrierId?: string
  clinicCarrierName?: string
  memberId?: string
  type?: string
}

export interface AppointmentData extends BaseModelData {
  cancellationReason?: string
  eligibilityStatus?: string
  eligibilityId?: string
  startAt: Date | string | moment.Moment
  endAt?: Date | string | moment.Moment
  scheduledAt?: Date | string | moment.Moment
  estimate?: EstimateSchema
  intakeId?: string
  intakeProgress: number
  intakeStatus?: Appointment.INTAKE_STATUS
  resultsStatus?: Appointment.RESULTS_STATUS
  resultsLinks?: string[]
  location?: FacilityData
  locationId: string
  notes?: string
  orderingPhysician?: string
  patient?: PatientData
  patientId: string
  paymentStatus?: string
  priority?: number
  products?: (string | number | ProductData)[]
  productIds: (string | number)[]
  productCategories?: string[]
  status?: string
  transactions?: (string | number | TransactionData)[]
  insurances: AppointmentInsurances[]
  surveyCompleted?: boolean
  waitList?: boolean
  type?: Appointment.TYPE
}

export class AppointmentModel extends BaseModel {
  cancellationReason?: string
  eligibilityStatus: Appointment.ELIGIBILITY_STATUS
  eligibilityId?: string
  startAt: moment.Moment
  endAt?: moment.Moment
  scheduledAt?: moment.Moment
  estimate?: EstimateSchema
  intakeId?: string
  intakeProgress: number
  intakeStatus: Appointment.INTAKE_STATUS
  locationId: string
  location?: Facility
  notes: string
  orderingPhysician?: string
  patientId: string
  patient?: Patient
  paymentStatus?: Appointment.PAYMENT_STATUS
  resultsStatus?: Appointment.RESULTS_STATUS
  resultsLinks?: string[]
  priority: number
  productIds: (string | number)[] = []
  products: ProductModel[] = []
  productCategories?: string[]
  status: Appointment.STATUS = Appointment.STATUS.OPEN
  transactions: TransactionModel[] = []
  transactionIds: (string | number)[] = []
  insurances: AppointmentInsurances[] = []
  updatedAt: moment.Moment
  surveyCompleted?: boolean
  waitList?: boolean
  type?: Appointment.TYPE

  constructor(data?: AppointmentData) {
    super(data)

    if (data) {
      this.patientId = data.patientId
      this.locationId = data.locationId
      this.status = data.status as Appointment.STATUS
      this.priority = data.priority
      this.notes = data.notes
      this.eligibilityStatus = data.eligibilityStatus as Appointment.ELIGIBILITY_STATUS
      this.eligibilityId = data.eligibilityId
      this.intakeId = data.intakeId
      this.intakeProgress = data.intakeProgress
      this.intakeStatus = data.intakeStatus || Appointment.INTAKE_STATUS.NONE
      this.paymentStatus = data.paymentStatus as Appointment.PAYMENT_STATUS
      this.resultsStatus = data.resultsStatus ?? Appointment.RESULTS_STATUS.NONE
      this.resultsLinks = data.resultsLinks
      this.cancellationReason = data.cancellationReason
      this.estimate = data.estimate
      this.orderingPhysician = data.orderingPhysician
      this.productCategories = data.productCategories
      this.insurances = data.insurances
      this.surveyCompleted = data.surveyCompleted
      this.waitList = data.waitList
      this.type = data.type

      if (data.patient) {
        this.patient = new Patient(data.patient)
      }

      if (data.location) {
        this.location = new Facility(data.location)
      }

      if (data.startAt) {
        this.startAt = this.transformDate(data.startAt)
      }

      if (data.endAt) {
        this.endAt = this.transformDate(data.endAt)
      }

      if (data.scheduledAt) {
        this.scheduledAt = this.transformDate(data.scheduledAt)
      }

      if (_.isArrayLike(data.products) && data.products.length > 0) {
        if (_.isObjectLike(data.products[0])) {
          this.products = _.map(data.products, (productData) => new ProductModel(productData as ProductData))
          this.productIds = _.map(this.products, (product) => product.sku)
        } else {
          this.productIds = data.products as (string | number)[]
        }
      }

      if (_.isArrayLike(data.transactions) && data.transactions.length > 0) {
        if (_.isObjectLike(data.transactions[0])) {
          this.transactions = _.map(data.transactions, (transactionData) => new TransactionModel(transactionData as TransactionData))
          this.transactionIds = _.map(this.transactions, (transaction) => transaction.id)
        } else {
          this.transactionIds = data.transactions as (string | number)[]
        }
      }
    }
  }

  get text(): string {
    return 'Appointment on ' + this.time
  }

  get priorityDisplay(): string {
    switch (this.priority) {
      case 3: return ''
      case 6: return 'ASAP'
    }

    return ''
  }

  get statusDisplay(): string {
    switch (this.status) {
      case Appointment.STATUS.OPEN: return 'Open'
      case Appointment.STATUS.CANCELLED: return 'Canceled'
    }
    return ''
  }

  get time(): string {
    return this.getTime()
  }

  get date(): string {
    if (this.startAt) {
      return this.startAt.format('MM/DD/YYYY')
    } else {
      return ''
    }
  }

  get notesHTML(): string {
    if (this.notes) {
      return this.notes.replace(/\n/g, '<br>')
    }
  }

  get cancelled(): boolean {
    return this.status === Appointment.STATUS.CANCELLED
  }

  get hasErrors(): boolean {
    return false
  }

  get appointmentStatusText(): string {
    switch (this.status) {
      case Appointment.STATUS.OPEN: return 'Open'
      case Appointment.STATUS.CANCELLED: return 'Cancelled'
      case Appointment.STATUS.IN_PROGRESS: return 'In progress'
      case Appointment.STATUS.COMPLETED: return 'Completed'
      default: return 'Unknown status: ' + this.status
    }
  }

  get intakeInProgress(): boolean {
    return [
      Appointment.INTAKE_STATUS.NONE,
      Appointment.INTAKE_STATUS.ACKNOWLEDGED,
      Appointment.INTAKE_STATUS.DELIVERED,
      Appointment.INTAKE_STATUS.PENDING,
    ].includes(this.intakeStatus)
  }

  get intakePatientCompleted(): boolean {
    return [
      Appointment.INTAKE_STATUS.NOT_REQUIRED,
      Appointment.INTAKE_STATUS.SUBMITTED,
      Appointment.INTAKE_STATUS.CONFIRMED,
      Appointment.INTAKE_STATUS.TECH_CONFIRMED,
      Appointment.INTAKE_STATUS.TECH_REVIEW,
      Appointment.INTAKE_STATUS.SYNCING,
      Appointment.INTAKE_STATUS.SYNCED,
    ].includes(this.intakeStatus)
  }

  get intakeStatusText(): string {
    switch (this.intakeStatus) {
      case Appointment.INTAKE_STATUS.NONE: return 'Patient has not started their intake forms'
      case Appointment.INTAKE_STATUS.ACKNOWLEDGED: return 'Patient has not started their intake forms, appointment has been acknowledged'
      case Appointment.INTAKE_STATUS.DELIVERED: return 'Intake forms have been delivered to the patient'
      case Appointment.INTAKE_STATUS.PENDING: return 'Patient has started, but not completed their intake forms'
      case Appointment.INTAKE_STATUS.SUBMITTED: return 'Intake forms submitted, system is processing responses'
      case Appointment.INTAKE_STATUS.CONFIRMED: return 'Intake forms has been confirmed'
      case Appointment.INTAKE_STATUS.TECH_CONFIRMED: return 'Intake forms has been signed by tech'
      case Appointment.INTAKE_STATUS.TECH_REVIEW: return 'Intake forms is under review'
      case Appointment.INTAKE_STATUS.SYNCING: return 'Intake forms are syncing to the EMR'
      case Appointment.INTAKE_STATUS.SYNCED: return 'Intake forms have been synced to EMR'
      case Appointment.INTAKE_STATUS.NOT_REQUIRED: return 'An intake is not required for this appointment'
      default: return 'Unknown status: ' + this.intakeStatus
    }
  }

  get intakeStatusColor(): string {
    switch (this.intakeStatus) {
      case Appointment.INTAKE_STATUS.NONE: return COLOR.DEFAULT
      case Appointment.INTAKE_STATUS.ACKNOWLEDGED: return COLOR.DEFAULT
      case Appointment.INTAKE_STATUS.DELIVERED: return COLOR.YELLOW
      case Appointment.INTAKE_STATUS.PENDING: return COLOR.FLASHING_YELLOW
      case Appointment.INTAKE_STATUS.SUBMITTED: return COLOR.FLASHING_GREEN
      case Appointment.INTAKE_STATUS.CONFIRMED: return COLOR.GREEN
      case Appointment.INTAKE_STATUS.TECH_CONFIRMED: return COLOR.GREEN
      case Appointment.INTAKE_STATUS.TECH_REVIEW: return COLOR.GREEN
      case Appointment.INTAKE_STATUS.SYNCING: return COLOR.GREEN
      case Appointment.INTAKE_STATUS.SYNCED: return COLOR.GREEN
      case Appointment.INTAKE_STATUS.NOT_REQUIRED: return COLOR.GREEN

      default: return COLOR.RED
    }
  }

  get chargeable(): boolean {
    return this.paymentStatus === Appointment.PAYMENT_STATUS.CHARGEABLE
  }

  get readyToPay(): boolean {
    return this.paymentStatus === Appointment.PAYMENT_STATUS.CHARGEABLE && this.intakePatientCompleted
  }

  get resultsReady(): boolean {
    return this.resultsStatus === Appointment.RESULTS_STATUS.AVAILABLE
  }

  get paymentStatusText(): string {
    switch (this.paymentStatus) {
      case Appointment.PAYMENT_STATUS.NONE: return 'No payments have been collected'
      case Appointment.PAYMENT_STATUS.CHARGEABLE: return 'No payments have been collected, but PatientPal collected a valid payment method'
      case Appointment.PAYMENT_STATUS.PARTIAL: return 'Partial payment has been received'
      case Appointment.PAYMENT_STATUS.FULL: return 'Full payment has been received'
      case Appointment.PAYMENT_STATUS.REFUND: return 'A refund is due'
      default: return 'Unknown status'
    }
  }

  get paymentStatusColor(): string {
    switch (this.paymentStatus) {
      case Appointment.PAYMENT_STATUS.NONE: return COLOR.DEFAULT
      case Appointment.PAYMENT_STATUS.CHARGEABLE: return COLOR.YELLOW
      case Appointment.PAYMENT_STATUS.PARTIAL: return COLOR.YELLOW
      case Appointment.PAYMENT_STATUS.FULL: return COLOR.GREEN
      case Appointment.PAYMENT_STATUS.REFUND: return COLOR.YELLOW
      default: return COLOR.RED
    }
  }

  get vip(): boolean {
    return !(this.patient && this.patient.vip)
  }

  isOrder() {
    return !this.startAt || this.type === Appointment.TYPE.ORDER
  }

  getTime(facility?: Facility, which: 'startAt' | 'endAt' = 'startAt'): string {
    if (!this[which]) {
      return null
    }

    const date = this.convertToFacilityTime(this[which].clone(), facility)
    return date.format(timeFormat)
  }

  getDate(facility?: Facility, which: 'startAt' | 'endAt' = 'startAt') {
    if (!this[which]) {
      return null
    }

    const date = this.convertToFacilityTime(this[which].clone(), facility)
    return date
  }

  convertToFacilityTime(date: moment.Moment, facility?: Facility): moment.Moment {
    if (facility?.timezone) {
      return date.tz(facility.timezone)
    } else if (this.location?.timezone) {
      return date.tz(this.location.timezone)
    } else {
      return date.local()
    }
  }

  toJSON(): any {
    let products: any = this.productIds
    let transactions: any = this.transactionIds

    if (this.products.length > 0) {
      products = this.products.map((product) => product.id)
    }

    if (this.transactions.length > 0) {
      transactions = this.transactions.map((transaction) => transaction.id)
    }

    return _.extend(super.toJSON(), {
      patient: this.patientId,
      locationId: this.locationId,
      startAt: this.startAt.toISOString(),
      endAt: this.endAt.toISOString(),
      status: this.status,
      priority: this.priority,
      orderingPhysician: this.orderingPhysician,
      notes: this.notes,
      intakeId: this.intakeId,
      intakeStatus: this.intakeStatus,
      eligibilityId: this.eligibilityId,
      eligibilityStatus: this.eligibilityStatus,
      paymentStatus: this.paymentStatus,
      products: _.clone(products),
      transactions: _.clone(transactions),
      estimate: _.clone(this.estimate),
      insurances: _.clone(this.insurances),
    })
  }
}

export namespace Appointment {
  export enum STATUS {
    OPEN = 'O',
    CANCELLED = 'C',
    IN_PROGRESS = 'I',
    COMPLETED = 'D',
  }

  export enum PAYMENT_STATUS {
    NONE = 'N',
    CHARGEABLE = 'C',
    PARTIAL = 'P',
    FULL = 'F',
    REFUND = 'R',
  }

  export enum INTAKE_STATUS {
    NONE = 'N',
    ACKNOWLEDGED = 'A',
    DELIVERED = 'D',
    PENDING = 'P',
    SUBMITTED = 'S',
    CONFIRMED = 'C',
    TECH_CONFIRMED = 'T',
    TECH_REVIEW = 'W',
    SYNCING = 'U',
    SYNCED = 'Y',
    NOT_REQUIRED = 'R',
  }

  export enum ELIGIBILITY_STATUS {
    NONE = 'N',
    INVALID = 'I',
    VALID = 'V',
  }

  export enum RESULTS_STATUS {
    NONE = 'N',
    AVAILABLE = 'A',
  }

  export enum TYPE {
    APPOINTMENT = 'A',
    ORDER = 'O',
  }
}
