// file deepcode ignore HardcodedNonCryptoSecret: file contains only public secrets

export const environment = {
  env: 'next',
  production: true,
  api: 'https://api.patientpal.io',
  wss: 'wss://hu9xd1meoi.execute-api.us-east-1.amazonaws.com/prod/',
  googleAnalyticsId: 'UA-115950284-2',
  googleMapsApiKey: 'AIzaSyDQwtD1hyMDqugLPIDCCk7GeOR5RNZRBhs',
  recaptcha3SiteKey: '6Lcnzp4UAAAAABbBfqSy6VP_8kmPIFMHRHjwt3VL',
  sentryDSN: 'https://320fbf707b834a1d9f71afad84f56254@sentry.io/1514871',
  vapidPublicKey:
    'BPM0iUc2R0knidDFngUmT5PSCZuIOVZ2fR0ATAAY2nvg3NDp38H0AAR_aR1b15WYSlvKzGk6ZV-CPgiBgGOajWs',
  stripePublicKey: 'pk_live_hJa6b1l3eQGnarEW6MBELgKC',
  tilledUrl: 'https://app.tilled.com',
  tilledPublicKey:
    'pk_sHLNPt51xfTzcEf6kMQLLCOs5P6OA2iOedtONx5MItTMf89n0AvBV0bzv7zk5KEb9Kmm9RLNyBPGpDq1Q2PD5EO3jzxhNqnGehOX', // need to be filled before deployment
  tilledProductionMode: true,
}
