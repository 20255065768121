import { HttpErrorResponse } from '@angular/common/http'

import { BaseModel } from '../../model/base-model'
import { PagedRestResponse } from './paged-rest-response'
import { RestResponseValidationError } from './rest-validation-error'

export interface DynamoDBAttributeValue {
  S?: string
  N?: string
}

export interface DynamoDBAttributeMap {
  [key: string]: DynamoDBAttributeValue
}

export interface RestResponse<T> {
  success?: boolean
  data: T
  message?: string
  errors?: RestResponseValidationError[]

  /**
   * @deprecated
   */
  lastEvaluatedId?: string

  /**
   * @deprecated
   */
  lastEvaluatedKey?: DynamoDBAttributeMap
}

export interface Action<T> {
  item: T;
  action: ActionType;
}

export type CollectionResponseOrError<T> = RestResponse<T[]> | HttpErrorResponse
export type ResponseOrError<T> = RestResponse<T> | HttpErrorResponse
export type ActionType = 'add' | 'update' | 'delete'

export function isError<T>(value: ResponseOrError<T> | CollectionResponseOrError<T>): boolean {
  return value instanceof HttpErrorResponse
}

export function isResponse<T>(value: ResponseOrError<T> | CollectionResponseOrError<T>): boolean {
  return !isError(value)
}

export function toData<T>(response: RestResponse<T>): T {
  return response.data
}

export function toSuccess<T>(response: RestResponse<T>): boolean {
  return response.success || false
}

export function toTotal<T>(response: PagedRestResponse<T>): number {
  return response.total || 0
}

export function updateCollection<T extends BaseModel>(items: T[], record: Action<T>): T[] {
  if (record.action === 'add') {
    // Return a new array with the added item pushed to it
    return [...items, record.item]
  } else if (record.action === 'update') {
    // Return a new array with the item replaced
    return items.map(item => item.id === record.item.id ? record.item : item)
  } else if (record.action === 'delete') {
    // Filter out the deleted item
    return items.filter(item => item.id !== record.item.id)
  }
  return [...items]
}
