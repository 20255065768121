import { BaseModel, BaseModelData, SelectOption } from '@mmx/shared'
import * as _ from 'lodash'

export interface InsuranceCarrierGroupData {
  carrierId: string,
  groupId?: string
  groupName?: string
  groupCarrierId?: string
  groupCarrierName?: string
  priority?: number
}

export interface InsuranceCarrierData extends BaseModelData {
  name: string
  insuranceType: string
  slug: string
  enabled: string
  source: string
  groupId?: string
  groupName?: string
  groupCarrierId?: string
  groupCarrierName?: string
  priority?: number
  popular?: boolean
  lookupId?: string
  requiresMemberId?: boolean
  patientDisplayName?: string
}

export class InsuranceCarrierModel extends BaseModel implements SelectOption<string> {
  name: string
  insuranceType: string
  slug: string
  enabled: string
  source: string
  groupId?: string
  groupName?: string
  groupCarrierId?: string
  groupCarrierName?: string
  priority?: number
  popular?: boolean
  lookupId?: string
  requiresMemberId?: boolean
  patientDisplayName?: string

  constructor(data: InsuranceCarrierData) {
    super(data)
    this.name = data.name
    this.insuranceType = data.insuranceType
    this.enabled = data.enabled
    this.source = data.source
    this.slug = data.slug
    this.groupId = data.groupId || ''
    this.groupName = data.groupName || ''
    this.groupCarrierId = data.groupCarrierId || ''
    this.groupCarrierName = data.groupCarrierName || ''
    this.priority = data.priority || null
    this.popular = data.popular
    this.lookupId = data.lookupId
    this.requiresMemberId = data.requiresMemberId
    this.patientDisplayName = data.patientDisplayName
  }

  toJSON() {
    return _.extend(super.toJSON(), {
      groupId: this.groupId,
      groupName: this.groupName,
      groupCarrierId: this.groupCarrierId,
      groupCarrierName: this.groupCarrierName,
      priority: this.priority,
    })
  }

  get value(): string {
    return this.id
  }

  get viewValue(): string {
    return this.patientDisplayName || this.groupName || this.name
  }

  get groupData(): InsuranceCarrierGroupData {
    return {
      carrierId: this.id,
      groupId: this.groupId,
      groupName: this.groupName,
      groupCarrierId: this.groupCarrierId,
      groupCarrierName: this.groupCarrierName,
      priority: this.priority,
    }
  }
}

export namespace InsuranceCarrier {
  export enum GROUP_TYPES {
    OFFICE = 'office',
    CARRIER = 'carrier',
  }
}
