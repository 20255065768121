import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { MatDialogModule } from '@angular/material/dialog'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'

import { AppLoaderComponent } from './app-loader.component'
import { AppLoaderService } from './app-loader.service'

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    MatProgressSpinnerModule,
  ],
  providers: [AppLoaderService],
  declarations: [AppLoaderComponent],
})
export class AppLoaderModule { }
