import { Patient } from './patient.model'

export enum CANNOT_TEXT_REASON {
  BLOCKED = 1,
  NO_PHONE_NUMBER,
  UNSUBSCRIBED,
  LANDLINE,
}

/**
 * Whether the patient has a phone number that we believe is textable.
 */
export function getCannotTextReason(patient: Patient): CANNOT_TEXT_REASON | undefined {
  if (patient.contactMethod === 'blocked') {
    return CANNOT_TEXT_REASON.BLOCKED
  }

  const phone = patient.primaryPhone

  if (!phone) {
    return CANNOT_TEXT_REASON.NO_PHONE_NUMBER
  } else if (phone.dnt) {
    return CANNOT_TEXT_REASON.UNSUBSCRIBED
  } else if (phone.ut) {
    return CANNOT_TEXT_REASON.LANDLINE
  }

  return
}

export function cannotText(patient: Patient) {
  return getCannotTextReason(patient) != null
}

export function canText(patient: Patient) {
  return getCannotTextReason(patient) == null
}

