import { filter } from 'lodash'

import { CRUD, CrudModel, SelectOption } from '../../classes'
import { AddressSchema } from '../../schema'
import { INSURANCE_PROVIDER_SOURCE, INSURANCE_PROVIDER_TRANSACTION_TYPE, InsuranceProvider } from '../insurance-provider'
import { InsuranceProviderLookupData } from './insurance-provider-lookup.data'
import { INSURANCE_PROVIDER_LOOKUP_STATUS } from './insurance-provider-lookup-status'
import { INSURANCE_PROVIDER_LOOKUP_TYPE } from './insurance-provider-lookup-type'

@CRUD({
  base: '/insurances/lookups',
})
export class InsuranceProviderLookup extends CrudModel implements SelectOption {
  clinicCarrierId?: string
  clinicCarrierName?: string
  status?: INSURANCE_PROVIDER_LOOKUP_STATUS
  address?: AddressSchema
  type?: INSURANCE_PROVIDER_LOOKUP_TYPE
  provider?: InsuranceProvider
  carrierId?: string // references the provider.id
  patientDisplayName?: string
  showToPatients?: boolean
  avoidEligibility?: boolean
  honorPolicyStatus?: boolean
  inNetwork?: boolean
  autoInvoice?: boolean
  noPaymentRequired?: boolean
  notes?: string
  enrolled?: boolean
  popular?: boolean

  get source(): INSURANCE_PROVIDER_SOURCE | undefined {
    return this.provider?.source
  }

  get enrollmentRequired(): boolean | undefined {
    return this.provider?.enrollmentRequired
  }

  get value(): string {
    return this.id
  }

  get viewValue(): string {
    const prefix = `${this.clinicCarrierName}`
    if (this.address?.zip) {
      const addressSegment = filter([
        this.address.address1,
        this.address.city,
        this.address.state,
        this.address.zip,
      ]).join(', ')
      return `${prefix} (${addressSegment})`
    }
    return prefix
  }

  constructor(data?: InsuranceProviderLookupData) {
    super(data, true)

    if (data?.provider) {
      this.provider = new InsuranceProvider(data.provider)
    }

    if (!data?.type) {
      this.type = INSURANCE_PROVIDER_LOOKUP_TYPE.MEDICAL_INSURANCE
    }
  }

  public supports(transactionType: INSURANCE_PROVIDER_TRANSACTION_TYPE): boolean {
    return this.provider?.supportedTransactions?.includes(transactionType) ?? false
  }

  override toJSON() {
    return Object.assign(super.toJSON(), {
      enrolled: this.enrolled,
      popular: this.popular,
      clinicCarrierId: this.clinicCarrierId,
      clinicCarrierName: this.clinicCarrierName,
      status: this.status,
      address: this.address,
      type: this.type,
      carrierId: this.carrierId,
      patientDisplayName: this.patientDisplayName,
      showToPatients: this.showToPatients,
      avoidEligibility: this.avoidEligibility,
      honorPolicyStatus: this.honorPolicyStatus,
      inNetwork: this.inNetwork,
      autoInvoice: this.autoInvoice,
      noPaymentRequired: this.noPaymentRequired,
      notes: this.notes,
    })
  }
}
