import { SelectOption } from '@mmx/shared'

export interface StateSelectOption extends SelectOption<string> {
  iso?: string
  abbreviation?: string
}

export const States: { [key: string]: StateSelectOption[] } = {
  US: [
    { viewValue: 'Alabama', value: 'AL' },
    { viewValue: 'Alaska', value: 'AK' },
    { viewValue: 'American Samoa', value: 'AS' },
    { viewValue: 'Arizona', value: 'AZ' },
    { viewValue: 'Arkansas', value: 'AR' },
    { viewValue: 'California', value: 'CA' },
    { viewValue: 'Colorado', value: 'CO' },
    { viewValue: 'Connecticut', value: 'CT' },
    { viewValue: 'Delaware', value: 'DE' },
    { viewValue: 'District Of Columbia', value: 'DC' },
    { viewValue: 'Federated States Of Micronesia', value: 'FM' },
    { viewValue: 'Florida', value: 'FL' },
    { viewValue: 'Georgia', value: 'GA' },
    { viewValue: 'Guam', value: 'GU' },
    { viewValue: 'Hawaii', value: 'HI' },
    { viewValue: 'Idaho', value: 'ID' },
    { viewValue: 'Illinois', value: 'IL' },
    { viewValue: 'Indiana', value: 'IN' },
    { viewValue: 'Iowa', value: 'IA' },
    { viewValue: 'Kansas', value: 'KS' },
    { viewValue: 'Kentucky', value: 'KY' },
    { viewValue: 'Louisiana', value: 'LA' },
    { viewValue: 'Maine', value: 'ME' },
    { viewValue: 'Marshall Islands', value: 'MH' },
    { viewValue: 'Maryland', value: 'MD' },
    { viewValue: 'Massachusetts', value: 'MA' },
    { viewValue: 'Michigan', value: 'MI' },
    { viewValue: 'Minnesota', value: 'MN' },
    { viewValue: 'Mississippi', value: 'MS' },
    { viewValue: 'Missouri', value: 'MO' },
    { viewValue: 'Montana', value: 'MT' },
    { viewValue: 'Nebraska', value: 'NE' },
    { viewValue: 'Nevada', value: 'NV' },
    { viewValue: 'New Hampshire', value: 'NH' },
    { viewValue: 'New Jersey', value: 'NJ' },
    { viewValue: 'New Mexico', value: 'NM' },
    { viewValue: 'New York', value: 'NY' },
    { viewValue: 'North Carolina', value: 'NC' },
    { viewValue: 'North Dakota', value: 'ND' },
    { viewValue: 'Northern Mariana Islands', value: 'MP' },
    { viewValue: 'Ohio', value: 'OH' },
    { viewValue: 'Oklahoma', value: 'OK' },
    { viewValue: 'Oregon', value: 'OR' },
    { viewValue: 'Palau', value: 'PW' },
    { viewValue: 'Pennsylvania', value: 'PA' },
    { viewValue: 'Puerto Rico', value: 'PR' },
    { viewValue: 'Rhode Island', value: 'RI' },
    { viewValue: 'South Carolina', value: 'SC' },
    { viewValue: 'South Dakota', value: 'SD' },
    { viewValue: 'Tennessee', value: 'TN' },
    { viewValue: 'Texas', value: 'TX' },
    { viewValue: 'Utah', value: 'UT' },
    { viewValue: 'Vermont', value: 'VT' },
    { viewValue: 'Virgin Islands', value: 'VI' },
    { viewValue: 'Virginia', value: 'VA' },
    { viewValue: 'Washington', value: 'WA' },
    { viewValue: 'West Virginia', value: 'WV' },
    { viewValue: 'Wisconsin', value: 'WI' },
    { viewValue: 'Wyoming', value: 'WY' },
  ],
  CA: [
    { value: 'AB', viewValue: 'Alberta' },
    { value: 'BC', viewValue: 'British Columbia' },
    { value: 'MB', viewValue: 'Manitoba' },
    { value: 'NB', viewValue: 'New Brunswick' },
    { value: 'NL', viewValue: 'Newfoundland' },
    { value: 'NS', viewValue: 'Nova Scotia' },
    { value: 'NU', viewValue: 'Nunavut' },
    { value: 'NT', viewValue: 'Northwest' },
    { value: 'ON', viewValue: 'Ontario' },
    { value: 'PE', viewValue: 'Prince Edward Island' },
    { value: 'QC', viewValue: 'Quebec' },
    { value: 'SK', viewValue: 'Saskatchewan' },
    { value: 'YT', viewValue: 'Yukon' },
  ],
  MX: [
    { value: 'AG', iso: 'AGU', abbreviation: 'Ags.', viewValue: 'Aguascalientes' },
    { value: 'BC', iso: 'BCN', abbreviation: 'B.C.', viewValue: 'Baja California' },
    { value: 'BS', iso: 'BCS', abbreviation: 'B.C.S.', viewValue: 'Baja California Sur' },
    { value: 'CM', iso: 'CAM', abbreviation: 'Camp.', viewValue: 'Campeche' },
    { value: 'CS', iso: 'CHP', abbreviation: 'Chis.', viewValue: 'Chiapas' },
    { value: 'CH', iso: 'CHH', abbreviation: 'Chih.', viewValue: 'Chihuahua' },
    { value: 'CO', iso: 'COA', abbreviation: 'Coah.', viewValue: 'Coahuila' },
    { value: 'CL', iso: 'COL', abbreviation: 'Col.', viewValue: 'Colima' },
    { value: 'DF', iso: 'CMX', abbreviation: 'CDMX', viewValue: 'Mexico City' },
    { value: 'DG', iso: 'DUR', abbreviation: 'Dgo.', viewValue: 'Durango' },
    { value: 'GT', iso: 'GUA', abbreviation: 'Gto.', viewValue: 'Guanajuato' },
    { value: 'GR', iso: 'GRO', abbreviation: 'Gro.', viewValue: 'Guerrero' },
    { value: 'HG', iso: 'HID', abbreviation: 'Hgo.', viewValue: 'Hidalgo' },
    { value: 'JA', iso: 'JAL', abbreviation: 'Jal.', viewValue: 'Jalisco' },
    { value: 'EM', iso: 'MEX', abbreviation: 'Méx.', viewValue: 'México' },
    { value: 'MI', iso: 'MIC', abbreviation: 'Mich.', viewValue: 'Michoacán' },
    { value: 'MO', iso: 'MOR', abbreviation: 'Mor.', viewValue: 'Morelos' },
    { value: 'NA', iso: 'NAY', abbreviation: 'Nay.', viewValue: 'Nayarit' },
    { value: 'NL', iso: 'NLE', abbreviation: 'N.L.', viewValue: 'Nuevo León' },
    { value: 'OA', iso: 'OAX', abbreviation: 'Oax.', viewValue: 'Oaxaca' },
    { value: 'PU', iso: 'PUE', abbreviation: 'Pue.', viewValue: 'Puebla' },
    { value: 'QT', iso: 'QUE', abbreviation: 'Qro.', viewValue: 'Querétaro' },
    { value: 'QR', iso: 'ROO', abbreviation: 'Q.R.', viewValue: 'Quintana Roo' },
    { value: 'SL', iso: 'SLP', abbreviation: 'S.L.P.', viewValue: 'San Luis Potosí' },
    { value: 'SI', iso: 'SIN', abbreviation: 'Sin.', viewValue: 'Sinaloa' },
    { value: 'SO', iso: 'SON', abbreviation: 'Son.', viewValue: 'Sonora' },
    { value: 'TB', iso: 'TAB', abbreviation: 'Tab.', viewValue: 'Tabasco' },
    { value: 'TM', iso: 'TAM', abbreviation: 'Tamps.', viewValue: 'Tamaulipas' },
    { value: 'TL', iso: 'TLA', abbreviation: 'Tlax.', viewValue: 'Tlaxcala' },
    { value: 'VE', iso: 'VER', abbreviation: 'Ver.', viewValue: 'Veracruz' },
    { value: 'YU', iso: 'YUC', abbreviation: 'Yuc.', viewValue: 'Yucatán' },
    { value: 'ZA', iso: 'ZAC', abbreviation: 'Zac.', viewValue: 'Zacatecas' },
  ],
}
