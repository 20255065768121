import { SelectOption } from '@mmx/shared'

export const Prefixes: SelectOption<string>[] = [
  'MR',
  'MRS',
  'MS',
  'DR',
  'PHD',
  'MD',
  'Prof',
].map((value) => {
  return {
    value,
    viewValue: value,
  }
})

export const Suffixes: SelectOption<string>[] = ['JR', 'II', 'III', 'IX'].map(
  (value) => {
    return {
      value,
      viewValue: value,
    }
  },
)
