import { extend } from 'lodash'

import { CRUD, CrudModel } from '../classes'
import { BaseModelData } from './base-model-data'

export type ResourceType = 'room' | 'equipment' | 'provider' | 'modality' | 'bodyPart' | 'other'

export interface ResourceData extends BaseModelData {
  name: string
  type: ResourceType
  facilityIds: string[] // location IDs
  isDeleted: boolean
  eid?: string
  npi?: string
  taxId?: string
}

@CRUD({
  base: '/crud/resources',
})
export class Resource extends CrudModel {
  name?: string
  type?: ResourceType
  facilityIds?: string[]
  isDeleted?: boolean
  eid?: string
  npi?: string
  taxId?: string
  overbookingEnabled?: boolean
  overbookingTimeDiff?: string
  overbookingMinMinutes?: number

  override get sortKey(): string {
    return this.name ? this.name.toLowerCase() : this.id
  }

  get url(): string {
    return ['', 'settings', 'resources', this.id].join('/')
  }

  constructor(data?: ResourceData) {
    super(data, true)
    this.type = data?.type ?? 'other'
  }

  override toJSON() {
    return extend(super.toJSON(), {
      name: this.name,
      type: this.type,
      facilityIds: this.facilityIds,
      isDeleted: this.isDeleted,
      eid: this.eid,
      npi: this.npi,
      taxId: this.taxId,
      overbookingEnabled: this.overbookingEnabled,
      overbookingTimeDiff: this.overbookingTimeDiff,
      overbookingMinMinutes: this.overbookingMinMinutes,
    })
  }
}
