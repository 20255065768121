import { Injectable } from '@angular/core'
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog'
import { Observable } from 'rxjs'

import { AppLoaderComponent } from './app-loader.component'

@Injectable()
export class AppLoaderService {
  isActive: boolean
  dialogRef: MatDialogRef<AppLoaderComponent>
  constructor(private dialog: MatDialog) { }

  public open(message: string = 'Please wait'): Observable<boolean> {
    if (this.isActive) {
      return
    }

    this.isActive = true

    const dialogConfig: MatDialogConfig = {
      disableClose: true,
      minWidth: '150px',
      maxWidth: '60vw',
      maxHeight: '60vh',
      panelClass: 'custom-dialog-container',
    }

    this.dialogRef = this.dialog.open(AppLoaderComponent, dialogConfig)
    this.dialogRef.componentInstance.message = message

    return this.dialogRef.afterClosed()
  }

  public close() {
    if (!this.dialogRef || !this.isActive) {
      return
    }

    this.isActive = false
    this.dialogRef.close()
  }
}
