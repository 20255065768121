import { BaseModel, BaseModelData } from '@mmx/shared'
import * as _ from 'lodash'

export interface PriceRequirements {
  carriers?: string[]
}

export interface PriceData extends BaseModelData {
  price?: number
  priceCurrency?: string
  requirements?: PriceRequirements
  validFrom?: Date
  validTo?: Date
  productId?: string
}

export class Price extends BaseModel {
  priceData: string
  price: number
  priceCurrency: string
  requirements?: PriceRequirements
  validFrom?: Date
  validTo?: Date
  productId?: string

  constructor(data: PriceData) {
    super(data)
    this.price = data.price
    this.priceCurrency = data.priceCurrency
    this.requirements = data.requirements || { }
    this.validFrom = data.validFrom
    this.validTo = data.validTo
    this.productId = data.productId
  }
  toJSON() {
    return _.extend(super.toJSON(), {
      price: this.price,
      priceCurrency: this.priceCurrency,
      requirements: this.requirements,
      productId: this.productId,
      validFrom: this.validFrom,
      validTo: this.validTo,
    })
  }

}
