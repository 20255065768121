<app-mmx-icon id="app-loader"></app-mmx-icon>

<router-outlet></router-outlet>

<div style="display: none;" id="offline-banner">
  <div class="offline-banner-bar" [class.offline]="offline.offline" [class.unreliable]="offline.unreliable" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="1rem">
    <ng-container *ngIf="offline.unreliable; else offlineMessage">
      <span translate="offline.unreliable"></span>
      <div fxFlex></div>
      <span *ngIf="offline.requestInProgress" translate="offline.retrying"></span>
      <span *ngIf="!offline.requestInProgress && offline.retryInSeconds !== null" translate="offline.retryingIn" [translateParams]="{value: offline.retryInSeconds}"></span>
      <mat-spinner color="warn" [mode]="offline.requestInProgress ? 'indeterminate' : 'determinate'" diameter="30" [value]="offline.retryPercentage"></mat-spinner>
    </ng-container>

    <ng-template #offlineMessage>
      <span translate="offline.offline"></span>
    </ng-template>
  </div>
</div>
