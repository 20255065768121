export const getFromLocalStorage = (key: string) => {
  try {
    const value = localStorage.getItem(key)
    return value ? JSON.parse(value) : ''
  } catch (e) {
    console.error('Error getting from local storage', e)
  }
}

export const setToLocalStorage = (key: string, value: any) => {
  try {
    localStorage.setItem(key, JSON.stringify(value || ''))
  } catch (e) {
    console.error('Error saving to local storage', e)
  }
}

export const removeFromLocalStorage = (key: string) => {
  try {
    localStorage.removeItem(key)
  } catch (e) {
    console.error('Error removing from local storage', e)
  }
}
