import { FormControl } from '@angular/forms'

import { SelectOption } from '../classes'
import { IDropdownOtherFieldType, IDropdownOtherFieldValue, IDropdownQuestionOtherFieldSettings } from '../model'

export const dropdownCompareFn = (valA: string | IDropdownOtherFieldValue, valB: string | IDropdownOtherFieldValue) => {
  if (valA && typeof valA === 'object' && valB && typeof valB === 'object') {
    return valA.type === valB.type
  }

  return valA === valB
}

export const otherFieldValidator = (otherFieldControl: FormControl | undefined) => {
  return (control: FormControl) => {
    if (!otherFieldControl) {
      return null
    }

    if (typeof control.value === 'object' && !control.value?.value) {
      return { customValueRequired: true }
    }

    return null
  }
}

export const isOtherOptionValue = (value: string | IDropdownOtherFieldValue) => {
  return typeof value === 'object' && value?.type === IDropdownOtherFieldType
}

export const addOtherOption = (
  options: SelectOption<string | IDropdownOtherFieldValue>[],
  settings: IDropdownQuestionOtherFieldSettings | undefined,
): SelectOption<string | IDropdownOtherFieldValue>[] => {
  const hasOtherField = options.find((o) => {
    if (typeof o.value === 'string') {
      return false
    }

    return o.value?.type === IDropdownOtherFieldType
  })

  if (settings?.enabled && !hasOtherField) {
    return [
      ...options,
      {
        viewValue: settings?.optionLabel || 'Other',
        value: {
          type: IDropdownOtherFieldType,
          value: '',
        },
      },
    ]
  }

  return options
}
