export enum AppTheme {
  GREEN = 'green',
  BLUE = 'blue',
  DARK_BLUE = 'dark-blue',
  DARK = 'dark',
  INDIGO = 'indigo',
  ORANGE = 'orange',
  PURPLE = 'purple',
  RED = 'red',
}
