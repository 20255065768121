import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { catchError, map } from 'rxjs/operators'

import { ClinicService } from '../services/clinic.service'

@Injectable()
export class ClinicExistsGuard {
  constructor(private clinicService: ClinicService) { }

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return this.clinicService.loadIfNotLoaded().pipe(
      map(clinic => {
        if (!clinic) {
          window.location.href = 'https://www.patientpal.com/login.html?clinic-not-found=1'
        }

        return !!clinic
      }),
      catchError(() => {
        return of(false)
      }),
    )
  }
}
