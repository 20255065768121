import * as moment from 'moment/moment'

import { Facility, Waitlist } from '../model'

export function getAvailableTimeIntervalsForLocations(locations: Facility[]) {
  const dayIntervals: Record<string, Waitlist.WeekdayTimeType[]> = moment.weekdays()
    .map((d) => d.substring(0, 3).toLowerCase())
    .reduce((acc, day) => ({ ...acc, [day]: [] }), {})

  for (const location of locations) {
    if (!location.timing?.workingDays) {
      continue
    }

    Object.entries(location.timing.workingDays).forEach(([day, hours]) => {
      if (hours.isClosed) {
        return
      }
      const intervals = getAvailableTimeIntervals(hours.openAt, hours.closeAt)
      dayIntervals[day] = Array.from(new Set([...dayIntervals[day], ...intervals]))
    })
  }

  return dayIntervals
}

export function getAvailableTimeIntervals(timeFrom: string, timeTo: string): Waitlist.WeekdayTimeType[] {
  const startTime = moment(timeFrom, 'HH:mm')
  const endTime = moment(timeTo, 'HH:mm')

  const intervals = [
    {
      start: moment().hour(5).minute(0),
      end: moment().hour(11).minute(59),
      type: Waitlist.WeekdayTimeType.MORNING,
    },
    {
      start: moment().hour(12).minute(0),
      end: moment().hour(16).minute(59),
      type: Waitlist.WeekdayTimeType.AFTERNOON,
    },
    {
      start: moment().hour(17).minute(0),
      end: moment().hour(4).minute(59).add(1, 'day'),
      type: Waitlist.WeekdayTimeType.EVENING,
    },
    {
      start: moment().hour(5).minute(0),
      end: moment().hour(4).minute(59).add(1, 'day'),
      type: Waitlist.WeekdayTimeType.ALL_DAY,
    },
  ]

  return intervals
    // we are only interested in intervals that overlap with the given time range
    // if an interval starts before the given time range and ends after the given time range, it overlaps
    // if an interval starts within the given time range, but ends after, it overlaps
    .filter((interval) => {
      if (startTime.isBefore(interval.start) && endTime.isBefore(interval.start)) {
        return false
      }

      if (startTime.isAfter(interval.end) && endTime.isAfter(interval.end)) {
        return false
      }

      return true
    })
    .map((interval) => interval.type)
}
