import {
  clone,
  cloneDeep,
  extend,
  get,
  identity,
  isEqual,
  isString,
  pickBy,
  trim,
  values,
} from 'lodash'
import * as moment from 'moment'

import { Breadcrumb } from '../../data'
import { AddressSchema } from '../../schema/address.schema'
import { PatientGuarantorSchema } from '../../schema/patient-guarantor.schema'
import { PatientNameSchema } from '../../schema/patient-name.schema'
import { BaseModel } from '../base-model'
import { BaseModelData } from '../base-model-data'
import { Waitlist } from '../waitlist'
import { canText } from './can-text'
import { PatientEmployer } from './patient-employer'
import { PatientPaymentProvider } from './patient-payment-provider'
import { PatientSexes } from './patient-sex'
import { Phone } from './phone.interface'

export interface PatientData extends BaseModelData {
  clinicId?: string
  eid?: string
  ekey?: string
  ssn?: string
  name?: PatientNameSchema
  address?: AddressSchema
  phones?: Phone[]
  email?: string
  emailBounced?: boolean
  language?: string
  contactMethod?: string
  sex?: PatientSexes
  dob?: Date | string | moment.Moment
  vip?: boolean
  guarantor?: PatientGuarantorSchema
  emergencyContact?: PatientGuarantorSchema
  employer?: PatientEmployer
  religion?: string
  maritalStatus?: string
  race?: string
  ethnicity?: string
  preferredFacilityId?: string
  mostRecentFacilityId?: string
  paymentProvider?: PatientPaymentProvider
  waitlistPreferences?: Waitlist.PatientWaitListPreferences
  customFields?: Record<string, any>
}

export class Patient extends BaseModel implements Breadcrumb {
  eid?: string
  ekey?: string
  ssn?: string
  name: PatientNameSchema
  address: AddressSchema
  email?: string
  emailBounced?: boolean
  language: string
  contactMethod?: string
  phones: Phone[]
  dob?: moment.Moment
  sex?: PatientSexes
  vip: boolean
  guarantor?: PatientGuarantorSchema
  emergencyContact?: PatientGuarantorSchema
  employer?: PatientEmployer
  religion?: string
  maritalStatus?: string
  race?: string
  ethnicity?: string
  preferredFacilityId?: string
  mostRecentFacilityId?: string
  paymentProvider?: PatientPaymentProvider
  waitlistPreferences?: Waitlist.PatientWaitListPreferences
  customFields?: Record<string, any>

  get hasAddress(): boolean {
    return isString(this.address.address1) && isString(this.address.zip)
  }

  constructor(data: PatientData) {
    super(data)

    this.clinicId = data.clinicId
    this.eid = data.eid
    this.ekey = data.ekey
    this.ssn = data.ssn
    this.name = data.name || {}
    this.phones = data.phones || []
    this.email = data.email
    this.emailBounced = data.emailBounced
    this.language = data.language || 'en'
    this.contactMethod = data.contactMethod
    this.sex = data.sex
    this.vip = !!data.vip
    this.religion = data.religion
    this.maritalStatus = data.maritalStatus
    this.race = data.race
    this.ethnicity = data.ethnicity
    this.preferredFacilityId = data.preferredFacilityId
    this.mostRecentFacilityId = data.mostRecentFacilityId
    this.waitlistPreferences = data.waitlistPreferences
    this.customFields = data.customFields ?? {}

    if (data.address && !isEqual(values(data.address), ['US'])) {
      this.address = data.address
    } else {
      this.address = {}
    }

    if (data.dob) {
      this.dob = this.transformDate(data.dob, false)
    }

    if (data.guarantor) {
      this.guarantor = pickBy(data.guarantor, identity) as PatientGuarantorSchema

      if (data.guarantor.dob) {
        this.guarantor.dob = this.transformDate(data.guarantor.dob, false)
      }

      this.guarantor.phones = this.guarantor.phones || []
    }

    if (data.emergencyContact) {
      this.emergencyContact = pickBy(data.emergencyContact, identity) as PatientGuarantorSchema

      if (data.emergencyContact.dob) {
        this.emergencyContact.dob = this.transformDate(
          data.emergencyContact.dob,
          false,
        )
      }

      this.emergencyContact.phones = this.emergencyContact.phones || []
    }

    this.employer = data.employer
  }

  get primaryPhone(): Phone | undefined {
    let phone: Phone | undefined

    if (this.phones && this.phones.length > 0) {
      // look for an explicitly set primary
      phone = this.phones.find(p => p.p)

      // look for the first number we have that isn't blocked or a landline
      if (!phone) {
        phone = this.phones.find(p => !p.dnt && !p.ut)
      }

      // if we still don't have a number, take the first number
      if (!phone) {
        phone = this.phones[0]
      }
    }

    return phone
  }

  get phone(): string | undefined {
    const phone = this.primaryPhone
    return phone?.n
  }

  get canText(): boolean {
    return canText(this)
  }

  get canEmail() {
    return this.email && this.email.length > 0 && !this.emailBounced
  }

  get canTextOrEmail() {
    return this.canText || this.canEmail
  }

  get hasGuarantor(): boolean {
    return !!get(this, 'guarantor.name.family') || !!get(this, 'guarantor.name.given')
  }

  get hasEmergencyContact(): boolean {
    return !!get(this, 'emergencyContact.name.family') || !!get(this, 'emergencyContact.name.given')
  }

  get shortName() {
    return `${this.name.family}, ${this.name.given}`.toUpperCase()
  }

  get fullName() {
    const firstGroup = [this.name.family, this.name.suffix]

    const first = trim(firstGroup.join(' '))

    const secondGroup = [
      this.name.prefix ? this.name.prefix + '.' : '',
      this.name.given,
      this.name.nick ? `"${this.name.nick}"` : '',
      this.name.middle,
    ].filter((part) => {
      return part && part !== ''
    })

    const second = trim(secondGroup.join(' '))

    if (first && second) {
      return `${first}, ${second}`
    }

    return this.standardName
  }

  get standardName() {
    if (this.name.family && this.name.given) {
      return `${this.name.given} ${this.name.family}`
    } else {
      return this.name.family || this.name.given || ''
    }
  }

  get text() {
    return this.shortName
  }

  get url() {
    return `/patient/${this.id}`
  }

  override toJSON() {
    let guarantor
    let emergencyContact

    if (this.guarantor?.dob) {
      guarantor = cloneDeep(this.guarantor)
      guarantor.dob = (this.guarantor.dob as moment.Moment).format('YYYY-MM-DD')
    }

    if (this.emergencyContact?.dob) {
      emergencyContact = cloneDeep(this.emergencyContact)
      emergencyContact.dob = (
        this.emergencyContact.dob as moment.Moment
      ).format('YYYY-MM-DD')
    }

    return extend(super.toJSON(), {
      eid: this.eid,
      ssn: this.ssn,
      name: clone(this.name),
      address: clone(this.address),
      phones: this.phones,
      email: this.email,
      language: this.language,
      contactMethod: this.contactMethod,
      preferredFacilityId: this.preferredFacilityId,
      sex: this.sex,
      dob: this.dob ? this.dob.format('YYYY-MM-DD') : null,
      vip: this.vip === true,
      guarantor,
      emergencyContact,
      employer: this.employer,
      religion: this.religion,
      maritalStatus: this.maritalStatus,
      race: this.race,
      ethnicity: this.ethnicity,
      waitlistPreferences: this.waitlistPreferences,
      customFields: this.customFields,
    })
  }
}
