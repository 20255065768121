export namespace Waitlist {
  export interface PatientAvailability {
    [day: string]: WeekdayTimeType[]
  }

  export interface PatientWaitListPreferences {
    locations: string[]
    weekdays: PatientAvailability
  }

  export enum WeekdayTimeType {
    MORNING = 'morning',
    AFTERNOON = 'afternoon',
    EVENING = 'evening',
    ALL_DAY = 'all-day',
  }
}
