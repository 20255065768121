import { Component, Input, OnInit } from '@angular/core'

import { Clinic } from '../../../shared/models'
import { AppService } from '../../services/app.service'
import { ClinicService } from '../../services/clinic.service'

@Component({
  selector: 'app-mmx-icon',
  templateUrl: './mmx-icon.component.html',
  styleUrls: ['./mmx-icon.component.scss'],
})
export class MMXIconComponent implements OnInit {
  @Input() paused = false
  @Input() color = 'primary'

  clinic: Clinic

  constructor(
    public app: AppService,
    public clinicService: ClinicService,
  ) { }

  ngOnInit() {
    if (!this.clinic && this.clinicService.clinic) {
      this.clinic = this.clinicService.clinic
    }
  }

  get classes() {
    return {
      [this.color]: true,
      paused: this.paused,
    }
  }
}
