import { SchedulingSection } from '../model'

/**
 * @TODO: Question types are not defined in a shared library
 * This is a temporary solution to add some type safety to these functions
 */
interface BaseSchedulingQuestion {
  id: string,
  ifQuestions?: BaseSchedulingQuestion[],
  showIfQuestions?: boolean
}

/**
 * Formats the scheduling question answers to only include visible questions
 * Returns an object with question IDs as keys and answers as values
 */
export const formatSchedulingQuestionAnswers = <T extends BaseSchedulingQuestion>(
  formValue: Record<string, any>,
  sections: SchedulingSection[] = [],
) => {
  const questionVisibility = getAllSectionQuestionsVisibility<T>(sections)

  return Object.values(formValue || {})
    .reduce((acc: Record<string, any>, questions: Record<string, any>) => {
      const visibleAnswers = Object.keys(questions || {}).filter((q) => questionVisibility[q]).reduce((qAcc, id) => ({
        ...qAcc,
        [id]: questions[id],
      }), {})

      return {
        ...acc,
        ...visibleAnswers,
      }
    }, {})
}

/**
 * Recursively get the visibility of all questions in all sections
 * Returns an object with question IDs as keys and boolean values
 */
const getAllSectionQuestionsVisibility = <T extends BaseSchedulingQuestion>(
  sections: SchedulingSection[],
): Record<string, boolean> => {
  return sections.reduce((acc, section) => {
    if (!section.questions) {
      return acc
    }

    return {
      ...acc,
      ...getQuestionVisibility<T>(section.questions as any as T[]),
    }
  }, {})
}

/**
 * Recursively get the visibility of all questions in a section
 * Returns an object with question IDs as keys and boolean values
 */
const getQuestionVisibility = <T extends BaseSchedulingQuestion>(questions: T[]): { [key: string]: boolean } => {
  return questions.reduce((acc, question) => {
    if (question.ifQuestions && question.showIfQuestions) {
      return {
        ...acc,
        [question.id]: true,
        ...getQuestionVisibility(question.ifQuestions),
      }
    }

    return {
      ...acc,
      [question.id]: true,
    }
  }, {})
}
