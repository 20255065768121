import { HttpErrorResponse, HttpHeaders } from '@angular/common/http'
import { EventEmitter, Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { Patient, PatientData, RestService } from '@mmx/shared'
import { Idle } from '@ng-idle/core'
import { GATEWAY_TIMEOUT, UNAUTHENTICATED } from 'app/shared/data/http-status-codes'
import { extend } from 'lodash'
import { BehaviorSubject, Observable, Subscription } from 'rxjs'
import { filter, first, map } from 'rxjs/operators'

import { ClinicService } from './clinic.service'
import { OfflineService } from './offline.service'

const TOKEN_STORAGE_KEY = 'ppc-token'
const FACILITY_STORAGE_TABLE = 'ppc-tablet'

export interface AuthorizationLike {
  isPatient?: boolean
}

export class Authorization {
  isLoggedIn = false
  _isPatient = false

  get isPatient() {
    return this._isPatient
  }

  set isPatient(val: boolean) {
    this.isLoggedIn = true
    this._isPatient = val
  }
}

interface AuthResponseSession {
  token: string
  valid?: boolean
  step?: string
}

interface AuthResponseUser {
  type: string // 'clinician' or 'patient'
  id: string
  email?: string
  name?: string
  title?: string
}

interface AuthResponseClinic {
  id: string
}

interface AuthResponseGoto {
  type: 'appointment'
  id: string
}

export interface AuthResponse {
  session: AuthResponseSession
  user: AuthResponseUser
  clinic: AuthResponseClinic
  goto?: AuthResponseGoto
}

export interface LoginPatientResult {
  valid: boolean
  users?: Patient[]
  goto?: AuthResponseGoto
}

export enum AuthChangeType {
  LOGIN = 'login',
  LOGOUT = 'logout',
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public user: Patient
  public changes: Observable<Patient>
  public authChanges$ = new EventEmitter<AuthChangeType>()
  private userChange = new BehaviorSubject<Patient>(null)
  private authorization: Authorization
  private interval: any

  constructor(
    private router: Router,
    private restService: RestService,
    private clinicService: ClinicService,
    private offline: OfflineService,
    private idle: Idle,
  ) {
    this.listenForStorageEvents()
  }

  launchRenewSession() {
    this.interval = setInterval(this.renewSession.bind(this), 10 * 60 * 1000) // 10 minutes
  }

  isUserLoggedIn() {
    return this.authorization && this.authorization.isLoggedIn
  }

  hasSessionToken() {
    return typeof this.sessionToken === 'string' && this.sessionToken.length > 0
  }

  checkAuthorization(): boolean {
    return this.authorization && this.authorization.isPatient
  }

  updateFromAuthRequest(data: AuthResponse) {
    if (data) {
      if (data.session?.token) {
        this.sessionToken = data.session.token
      }

      if (data.user) {
        this.authorization = new Authorization()

        if (data.user.type === 'patient') {
          const patient = new Patient({ ...data.user, clinicId: data.clinic.id } as any)
          this.user = patient
          this.userChange.next(patient)
          this.authorization.isPatient = true
          this.authChanges$.emit(AuthChangeType.LOGIN)
        }
      }
    }
  }

  verifyPatient(mfaCode: string): Observable<void> {
    const route = '/patient/login/verify'

    const body: any = {
      clinicId: this.clinicService.clinicId,
      mfa: mfaCode,
    }

    const options = {
      headers: new HttpHeaders({
        Authorization: this.sessionToken || '',
      }),
    }

    return this.restService.post<AuthResponse>(route, body, options).pipe(
      map((result) => {
        this.updateFromAuthRequest(result.data)
        return
      }),
    )
  }

  loginPatient(params: {
    phone?: string,
    email?: string,
    dob?: string,
    recaptcha?: string,
  }): Observable<LoginPatientResult> {
    const route = '/patient/login'
    const body = extend({ }, params, {
      clinicId: this.clinicService.clinicId,
    })

    return this.restService.post<AuthResponse>(route, body).pipe(
      map((result) => {
        this.reset()
        this.authChanges$.emit(AuthChangeType.LOGIN)

        if (result.data.session) {
          this.sessionToken = result.data.session.token
          return {
            valid: result.data.session.valid,
            goto: result.data.goto,
          }
        } else {
          // More than one user share same credentials
          return {
            valid: true,
            ...result.data,
          }
        }
      }),
    )
  }

  resetPatientSession(token: string) {
    this.reset()
    this.sessionToken = token
  }

  updatePatient(patient: Patient) {
    const body = patient.toJSON()

    const route = '/patient'
    return this.restService.put<PatientData>(route, body)
  }

  logout(clearSessionToken = true) {
    this.reset(clearSessionToken)
    this.idle.stop()

    if (this.interval) {
      clearInterval(this.interval)
    }

    this.authChanges$.emit(AuthChangeType.LOGOUT)
    this.router.navigate(['/login'])
  }

  get sessionToken(): string | null {
    return localStorage.getItem(TOKEN_STORAGE_KEY)
  }

  set sessionToken(value: string | null) {
    localStorage.setItem(TOKEN_STORAGE_KEY, value)
  }

  get facilityTable() {
    return localStorage.getItem(FACILITY_STORAGE_TABLE)
  }

  set facilityTable(value: string) {
    localStorage.setItem(FACILITY_STORAGE_TABLE, value)
  }

  reset(clearSessionToken = true) {
    this.authorization = new Authorization()
    try {
      if (clearSessionToken) {
        localStorage.removeItem(TOKEN_STORAGE_KEY)
      }
    } catch (e) {}
  }

  hasPaymentMethod() {
    return !!this.user?.paymentProvider?.methodId
  }

  private renewSession() {
    if (this.hasSessionToken() && this.isUserLoggedIn() && !this.offline.offline) {
      let subscription: Subscription | undefined
      subscription = this.userChange.pipe(
        filter((user) => user != null),
        first(),
      ).subscribe((user) => {
        if (subscription) {
          subscription.unsubscribe()
        }
        if (user) {
          this.restService.get<AuthResponse>('/auth', undefined, {
            headers: new HttpHeaders({
              'Authorization': this.sessionToken,
              'WWW-Clinic-ID': user.clinicId,
            }),
          }).subscribe({
            next: (result) => {
              if (subscription) {
                subscription.unsubscribe()
              }

              this.updateFromAuthRequest(result.data)
            },
            error: (err: HttpErrorResponse) => {
              if (err.status === UNAUTHENTICATED) {
                this.router.navigateByUrl('/login')
              } else if (err.status !== GATEWAY_TIMEOUT) {
                // we ignore the occasional gateway timeout, it won't cause a problem for the user
                throw err
              }
            },
          })
        }
      })
    }
  }

  /**
   * add event listener for when the session token is changed by another tab
   * @see https://developer.mozilla.org/en-US/docs/Web/API/Window/storage_event
   */
  private listenForStorageEvents() {
    window.addEventListener('storage', async (event) => {
      if (event.storageArea != localStorage) return
      if (event.key === TOKEN_STORAGE_KEY && this.isUserLoggedIn()) {
        this.logout(false)
      }
    })
  }
}
