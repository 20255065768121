import { Injectable } from '@angular/core'
import { GATEWAY_TIMEOUT, NETWORK_FAILURE } from 'app/shared/data/http-status-codes'
import { BehaviorSubject, Observable, throwError, timer } from 'rxjs'
import { finalize, mergeMap, retryWhen, tap } from 'rxjs/operators'

@Injectable({
  providedIn: 'root',
})
export class OfflineService {
  online = true
  unreliable = false
  onlineStatusChange = new BehaviorSubject(this.online)
  unreliableChange = new BehaviorSubject(this.unreliable)
  requestInProgress = false
  retryInSeconds: number
  retrySecondsRemaining: number
  countDownInterval: any

  get retryPercentage(): number {
    return (this.retryInSeconds - (this.retrySecondsRemaining / 1000)) / this.retryInSeconds * 100
  }

  get offline(): boolean {
    return !this.online
  }

  get banner() {
    return document.getElementById('offline-banner')
  }

  constructor() {
    window.addEventListener('online', () => this.onOnline())
    window.addEventListener('offline', () => this.onOffline())
  }

  untilSuccess<T>(obs: () => Observable<T>): Observable<T> {
    const maxRetryAttempts: number | null = null // 10
    const scalingDuration = 1000
    const excludedStatusCodes = []
    const includedStatusCodes = [NETWORK_FAILURE, GATEWAY_TIMEOUT]

    return obs().pipe(
      retryWhen((attempts: Observable<any>) => {
        return attempts.pipe(
          mergeMap((error, i) => {
            this.requestInProgress = false

            const retryAttempt = i + 1
            // if maximum number of retries have been met
            // or response is a status code we don't wish to retry, throw error
            if (
              (maxRetryAttempts != null && retryAttempt > maxRetryAttempts) ||
              (includedStatusCodes.length > 0 && !includedStatusCodes.includes(error.status)) ||
              (excludedStatusCodes.length > 0 && excludedStatusCodes.includes(error.status))
            ) {
              return throwError(() => error)
            }

            if (this.unreliable === false) {
              this.setUnreliable(true)
            }

            clearInterval(this.countDownInterval)
            this.retryInSeconds = retryAttempt
            this.retrySecondsRemaining = this.retryInSeconds * 1000 // minus 1 so it starts off with progress
            this.countDownInterval = setInterval(() => {
              this.retrySecondsRemaining -= 100
              if (this.retrySecondsRemaining <= 0) {
                clearInterval(this.countDownInterval)
              }
            }, 100)

            // retry after 1s, 2s, etc...
            return timer(retryAttempt * scalingDuration).pipe(
              tap(() => {
                this.requestInProgress = true
              }),
            )
          }),
          finalize(() => {
            this.requestInProgress = false
            this.setUnreliable(false)
            clearInterval(this.countDownInterval)
            this.retryInSeconds = 0
            this.retrySecondsRemaining = 0
          }),
        )
      }),
    )
  }

  private onOnline() {
    this.setOffline(true)
  }

  private onOffline() {
    this.setOffline(false)
  }

  private setOffline(online: boolean) {
    this.online = online
    this.onlineStatusChange.next(this.online)

    if (this.banner) {
      if (this.unreliable || this.offline) {
        this.banner.style.display = 'block'
      } else {
        this.banner.style.display = 'none'
        clearInterval(this.countDownInterval)
      }
    }
  }

  private setUnreliable(unreliable: boolean) {
    this.unreliable = unreliable
    this.unreliableChange.next(this.unreliable)

    if (this.banner) {
      if (this.unreliable || this.offline) {
        this.banner.style.display = 'block'
      } else {
        this.banner.style.display = 'none'
        clearInterval(this.countDownInterval)
      }
    }
  }
}
