import { getCurrencySymbol } from '@angular/common'
import { MaskitoOptions } from '@maskito/core'
import { maskitoNumberOptionsGenerator } from '@maskito/kit'
import { isNumber } from 'lodash'

export function amountMask(prefix?: string): MaskitoOptions {
  return maskitoNumberOptionsGenerator({
    decimalZeroPadding: true,
    precision: 2,
    thousandSeparator: ',',
    decimalSeparator: '.',
    min: 0,
    prefix: prefix ? getCurrencySymbol(prefix, 'wide') : getCurrencySymbol('USD', 'wide'),
  })
}

export const percentageMask: MaskitoOptions =
  maskitoNumberOptionsGenerator({
    postfix: '%',
    min: 0,
    max: 100,
    precision: 2,
  })

export function numericValuesMaskSanitizer(value: string | number | null,
  currencyPrefix = getCurrencySymbol('USD', 'wide')): number {
  if (isNumber(value)) {
    return value
  }

  if (!value) {
    return NaN
  }

  if (value.startsWith(currencyPrefix)) {
    value = value.slice(1)
  }

  value = value.replace(/,/g, '')
  // regex represents next value formats: 123, 123.456, 123%, 123.456%
  if (/^\d+(\.?\d+)?%?$/g.test(value)) {
    return parseFloat(value)
  }

  return NaN
}
