export class Address {
  address1?: string
  address2?: string
  address3?: string
  sublocality?: string
  city?: string
  state?: string
  zip?: string
  country?: string

  constructor() { }
}
