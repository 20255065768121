import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { RestService } from '@mmx/shared'
import { concatMap, EMPTY } from 'rxjs'
import { catchError } from 'rxjs/operators'

import { AuthResponse, AuthService } from './auth.service'
import { ClinicService } from './clinic.service'

@Injectable()
export class AuthenticatedRestService extends RestService {
  constructor(
    protected http: HttpClient,
    protected clinicService: ClinicService,
    protected authService: AuthService,
  ) {
    super(http)
  }

  checkAuth(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (this.authService.hasSessionToken() && !this.authService.isUserLoggedIn()) {
        this.clinicService.loadIfNotLoaded()
          .pipe(
            concatMap(() => this.get<AuthResponse>('/auth')),
            catchError(() => {
              this.authService.logout()
              return EMPTY
            }),
          )
          .subscribe({
            next: (result) => {
              this.authService.updateFromAuthRequest(result.data)
              resolve(this.authService.checkAuthorization())
            },
            error: (err) => {
              // reset the session token if the token was invalid
              this.authService.sessionToken = ''
              reject(err)
            },
          })
      } else {
        resolve(this.authService.checkAuthorization())
      }
    })
  }

  protected getDefaultHeaders(): HttpHeaders {
    let headers = super.getDefaultHeaders()

    if (this.authService.sessionToken) {
      headers = headers.append('Authorization', this.authService.sessionToken)
    }

    return headers
  }
}
