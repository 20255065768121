import { SelectOption } from '../../classes'
import { BaseModel } from '..'
import { InsuranceProviderData } from './insurance-provider.data'
import { INSURANCE_PROVIDER_SOURCE } from './insurance-provider-source'
import { INSURANCE_PROVIDER_TRANSACTION_TYPE } from './insurance-provider-transaction-type'
import { INSURANCE_PROVIDER_TYPE } from './insurance-provider-type'

/**
 * InsuranceProvider is a system-wide record for a Payer available from Availity
 * or a similar clearinghouse.
 *
 * THIS IS NOT A CLINIC-SPECIFIC RECORD.
 * InsuranceProviderLookup InsuranceCarrierLookup is clinic-specific.
 */
export class InsuranceProvider extends BaseModel implements SelectOption {
  name: string
  payerName: string
  insuranceType: INSURANCE_PROVIDER_TYPE
  slug: string
  enabled: boolean
  source: INSURANCE_PROVIDER_SOURCE
  enrollmentRequired?: boolean
  portalUrl?: string
  state?: string
  requiresMemberId?: boolean
  serviceTypesUnsupported?: string[]
  supportedTransactions?: INSURANCE_PROVIDER_TRANSACTION_TYPE[]

  constructor(data: InsuranceProviderData) {
    super(data)
    this.name = data.name
    this.payerName = data.payerName
    this.insuranceType = data.insuranceType
    this.enabled = data.enabled
    this.source = data.source
    this.slug = data.slug
    this.enrollmentRequired = data.enrollmentRequired
    this.portalUrl = data.portalUrl
    this.state = data.state
    this.requiresMemberId = data.requiresMemberId
    this.serviceTypesUnsupported = data.serviceTypesUnsupported
    this.supportedTransactions = data.supportedTransactions
  }

  get value(): string {
    return this.id
  }

  get viewValue(): string {
    return this.name
  }
}
