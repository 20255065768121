import { AbstractControl, UntypedFormGroup, ValidatorFn } from '@angular/forms'
import { includes } from 'lodash'
import * as moment from 'moment'

// convert phone number to standard format: +12345678901
export function standardizePhone(phoneNumber?: string): string {
  const stripped = (phoneNumber || '').replace(/[^0-9]+/g, '')

  if (stripped.length === 10) {
    return '+1' + stripped
  } else if (stripped.length === 11) {
    return '+' + stripped
  } else {
    return stripped
  }
}

export function formatPhoneNumber(phoneNumber: string, format: '(111) 222-3333' | '111-222-3333' = '(111) 222-3333') {
  if (typeof phoneNumber === 'string') {
    const s = phoneNumber.replace(/\D/g, '')

    if (s.length === 11 && phoneNumber.startsWith('+1')) {
      const m = s.match(/^\d(\d{3})(\d{3})(\d{4})$/)

      if (m) {
        switch (format) {
          case '(111) 222-3333': return `(${m[1]}) ${m[2]}-${m[3]}`
          case '111-222-3333': return `${m[1]}-${m[2]}-${m[3]}`
        }
      }
    } else if (s.length === 12) {
      // example: +52 999 925 1544
      const m = s.match(/^\d\d(\d{3})(\d{3})(\d{4})$/)

      if (m) {
        switch (format) {
          case '(111) 222-3333': return `(${m[1]}) ${m[2]}-${m[3]}`
          case '111-222-3333': return `${m[1]}-${m[2]}-${m[3]}`
        }
      }
    }
  }

  return phoneNumber
}

export const phoneMask = ['+', '1', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]

export function minDateValidator(minDate: moment.Moment, currentValue?: moment.Moment): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = moment(control.value, moment.HTML5_FMT.DATE)
    const forbidden = moment(minDate).isAfter(value)
    if (currentValue && currentValue.isSame(value, 'day')) {
      return null
    }
    return forbidden ? { minDate: { value: control.value } } : null
  }
}

export function maxDateValidator(maxDate: moment.Moment): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const forbidden = moment(maxDate).isBefore(moment(control.value, moment.HTML5_FMT.DATE))
    return forbidden ? { maxDate: { value: control.value } } : null
  }
}

export function oneOfValidator(options: string[]): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    return includes(options, control.value) ? null : { oneOf: { value: control.value } }
  }
}

export function ssnValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (control.value && control.value.length) {
      if (control.value.length < 11) {
        return { ssn: true }
      } else {
        try {
          const ssn = control.value.split('-')
          if (ssn[0] === '000' || ssn[0] === '666' || (parseInt(ssn[0], 10) >= 900 && parseInt(ssn[0], 10) <= 999) ||
            ssn[1] === '00' || ssn[2] === '0000') {
            return { ssn: true }
          }
        } catch (error) {
          return { ssn: true }
        }
      }
    }
  }
}

export function phoneValidator(): ValidatorFn {
  const phoneMatcherUI = /^\+1\s\(\d{3}\)\s\d{3}\-\d{4}$/
  const phoneMatcherDB = /^\+1\d{10}$/
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (control.value && control.value.length > 0 && !phoneMatcherUI.test(control.value) && !phoneMatcherDB.test(control.value)) {
      return { phone: true }
    } else {
      return null
    }
  }
}

export function atLeastOneCheckboxCheckedValidator(minRequired = 1): ValidatorFn {
  return function validate(formGroup: UntypedFormGroup) {
    let checked = 0

    Object.keys(formGroup.controls).forEach(key => {
      const control = formGroup.controls[key]

      if (control.value) {
        checked++
      }
    })

    if (checked < minRequired) {
      return {
        requireCheckboxToBeChecked: true,
      }
    }

    return null
  }
}
