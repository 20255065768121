import { CRUD, CrudModel } from '../../classes'
import { BaseModelData, INSURANCE_PROVIDER_LOOKUP_TYPE, ModelDate } from '..'

export enum PatientInsuranceStatus {
  NotVerified = 'N',
  Active = 'A',
  Inactive = 'I',
}

export enum PatientInsuranceType {
  PRIMARY = 'P',
  SECONDARY = 'S',
  TERTIARY = 'T',
}

export enum PatientEligibilityStatus {
  NONE = 'N',
  HARD_FAIL = 'F',
  SOFT_FAIL = 'E',
  INVALID = 'IV',
  INACTIVE = 'I',
  ACTIVE = 'V',
  AVOIDED = 'A',
}

export interface PatientInsuranceData extends BaseModelData {
  patientId?: string
  eid?: string
  carrierLookupId?: string
  clinicCarrierId?: string
  clinicCarrierName?: string
  memberId?: string
  groupNumber?: string
  groupName?: string
  effectiveFrom?: ModelDate
  effectiveTo?: ModelDate
  status?: PatientInsuranceStatus
  eligibilityStatus?: PatientEligibilityStatus
  type?: PatientInsuranceType
  lastVerifiedAt?: ModelDate
  lastVerifiedBy?: string
  eligibilityId?: string
  carrierId?: string
  lookupType?: INSURANCE_PROVIDER_LOOKUP_TYPE
}

@CRUD({
  base: '/insurances/policies',
})
export class PatientInsurance extends CrudModel implements PatientInsuranceData {
  patientId?: string
  eid?: string
  carrierLookupId?: string
  clinicCarrierId?: string
  clinicCarrierName?: string
  memberId?: string
  groupNumber?: string
  groupName?: string
  effectiveFrom?: string
  effectiveTo?: string
  status?: PatientInsuranceStatus
  eligibilityStatus?: PatientEligibilityStatus
  type: PatientInsuranceType
  lastVerifiedAt?: moment.Moment
  lastVerifiedBy?: string
  eligibilityId?: string
  carrierId?: string
  lookupType?: INSURANCE_PROVIDER_LOOKUP_TYPE

  constructor(data?: PatientInsuranceData) {
    super(data, true)

    // default type is primary
    this.type = data?.type ?? PatientInsuranceType.PRIMARY
  }

  // convert type to a number to allow policies to be sorted
  get typeIndex(): number {
    switch (this.type) {
      case PatientInsuranceType.TERTIARY: return 2
      case PatientInsuranceType.SECONDARY: return 1
      default: return 0
    }
  }

  get typeText(): string {
    switch (this.type) {
      case PatientInsuranceType.TERTIARY: return 'insurances.type.T'
      case PatientInsuranceType.SECONDARY: return 'insurances.type.S'
      default: return 'insurances.type.P'
    }
  }

  override toJSON() {
    return Object.assign(super.toJSON(), {
      patientId: this.patientId,
      eid: this.eid,
      carrierLookupId: this.carrierLookupId,
      clinicCarrierId: this.clinicCarrierId,
      clinicCarrierName: this.clinicCarrierName,
      memberId: this.memberId,
      groupName: this.groupName,
      groupNumber: this.groupNumber,
      effectiveFrom: this.effectiveFrom,
      effectiveTo: this.effectiveTo,
      status: this.status,
      eligibilityStatus: this.eligibilityStatus,
      type: this.type,
      lastVerifiedAt: this.lastVerifiedAt,
      lastVerifiedBy: this.lastVerifiedBy,
    })
  }
}
