import { BaseModelData, CRUD, CrudModel } from '@mmx/shared'

export interface ScoreConfiguration {
  /**
  * The key is the value of the option, and the value is the score.
  */
  scores: Record<string, number>

  /**
   * If true, the score will be shown in the intake form next to the answer
   */
  showAnswerScore: boolean

  /**
   * If true, the value of the answer will be used as the score
   * instead of the score for the option
   * Used for slider questions
   */
  useValueAsScore: boolean
}

export interface IntakeScorerConfiguration {
  method: IntakeScorerMethod

  /**
     * A map of question id keys to their score configuration
     */
  questions: Record<string, ScoreConfiguration>
}

export enum IntakeScorerMethod {
  SUM = 'sum',
}

export interface IntakeScorerData extends BaseModelData {
  name: string
  description?: string
  configuration: IntakeScorerConfiguration
}

@CRUD({
  base: '/crud/intake-scorer',
})
export class IntakeScorerModel extends CrudModel {
  name!: string
  description?: string
  configuration!: IntakeScorerConfiguration

  constructor(data?: IntakeScorerData) {
    super(data, true)
  }

  override toJSON() {
    return {
      ...super.toJSON(),
      name: this.name,
      description: this.description,
      configuration: this.configuration,
    }
  }
}
