import { BaseModel, BaseModelData, SelectOption } from '@mmx/shared'
import { extend, filter, find, map } from 'lodash'

import { Price, PriceData } from './price.model'

export interface ProductData extends BaseModelData {
  type: string
  sku: string
  eid?: string
  code?: string
  serviceCodes?: string[]
  name?: string
  description?: string
  categoryId?: string
  defaultDuration?: number
  price?: PriceData
  prices?: PriceData[]
  resources?: string[]
}

export class ProductModel extends BaseModel {
  type: Product.TYPE
  sku: string
  eid: string
  code: string
  serviceCodes?: string[]
  name: string
  description: string
  categoryId?: string
  defaultDuration?: number
  price?: Price
  prices?: Price[]
  resources?: string[]

  constructor(data?: ProductData) {
    super(data)

    if (data) {
      this.type = data.type as Product.TYPE
      this.sku = data.sku
      this.eid = data.eid
      this.code = data.code
      this.serviceCodes = data.serviceCodes
      this.name = data.name
      this.description = data.description
      this.categoryId = data.categoryId
      this.defaultDuration = data.defaultDuration
      this.resources = data.resources || []

      if (data.price) {
        this.price = new Price(data.price)
      }

      if (data.prices) {
        this.prices = map(data.prices, (priceData) => {
          priceData.productId = this.id
          return new Price(priceData)
        })
      }
    }
  }

  get typeDisplay(): string {
    const type = this.type || Product.TYPE.MEDICAL_PROCEDURE
    const option = find(ProductTypes, (opt) => {
      return opt.value === type
    })
    return option ? option.viewValue : ''
  }

  get searchTerms(): string {
    return filter([
      this.name.toLocaleLowerCase(),
      this.code,
      this.sku.toLowerCase(),
    ]).join(' ')
  }

  toJSON() {
    return extend(super.toJSON(), {
      type: this.type,
      sku: this.sku,
      eid: this.eid,
      code: this.code,
      serviceCodes: this.serviceCodes,
      name: this.name,
      description: this.description,
      categoryId: this.categoryId,
      defaultDuration: this.defaultDuration,
    })
  }
}

export namespace Product {
  export enum TYPE {
    MEDICAL_PROCEDURE = 'M',
    SERVICE = 'S',
    PRODUCT = 'P',
  }
}

export const ProductTypes: SelectOption<string>[] = [
  { value: Product.TYPE.MEDICAL_PROCEDURE, viewValue: 'Medical Procedure' },
  { value: Product.TYPE.SERVICE, viewValue: 'Service' },
  { value: Product.TYPE.PRODUCT, viewValue: 'Product' },
]
