import { BaseModel, BaseModelData, ModelDate, PaymentProvider, TaxRate, TaxRateData } from '@mmx/shared'
import { cloneDeep, extend, unset } from 'lodash'
import * as moment from 'moment'

import { AddressSchema } from './schemas/address.schema'
import { EstimateSchema } from './schemas/estimate.schema'
import { PatientNameSchema } from './schemas/patient-name.schema'

export interface InvoiceLineItem {
  // order: number // unique number
  amount: number
  currency?: string
  name?: string
  description: string
  discountable?: boolean // defaults to "true", meaning discounts added by patient will discount this line item
  period?: {
    start?: Date,
    end?: Date,
  }
  quantity?: number // defaults to "1"
  unit_amount?: number
  // taxRates: TaxRate[]
  proration?: boolean // whether this is a proration
}

export interface InvoiceTaxAmount {
  amount: number // The amount, in cents, of the tax.
  inclusive?: boolean // Whether this tax amount is inclusive or exclusive.
  taxRateId?: string
  taxRate?: TaxRate | TaxRateData // The tax rate that was applied to get this tax amount.
}

export interface InvoicePlan {
  total?: number
  subtotal?: number
  fee?: number
  feePercentage?: number
  feeAmount?: number
  months?: number
  monthlyAmount?: number
  firstPayment?: number
  cardId?: string
  paymentMethodId?: string
  nextPaymentAt?: Date
  startAt?: Date
  endAt?: Date
}

export interface InvoiceDiscount {
  percent: number
  amount: number
  total: number
}

interface InvoiceTemplate {
  description?: string
  footer?: string
  postPayment?: string
  due?: number
  periodStart?: ModelDate
  periodEnd?: ModelDate
}

interface StatusTransitions {
  [key: string]: StatusTransitionsDetails
}

interface StatusTransitionsDetails {
  at: string
  by: string
}

export interface InvoiceData extends BaseModelData {
  paid?: boolean
  patientId: string
  appointmentId?: string
  lineItems: InvoiceLineItem[]
  status?: Invoice.STATUS
  paymentOption?: Invoice.PAYMENT_OPTION
  paymentProvider?: PaymentProvider
  plan?: InvoicePlan
  cardId?: string
  amountDue?: number
  amountPaid?: number
  amountRemaining?: number
  attemptCount?: number
  subtotal: number
  tax?: number
  taxAmount?: number
  taxAmounts?: InvoiceTaxAmount[]
  feeDue?: number
  feePaid?: number
  feeRemaining?: number
  total: number
  collectionMethod?: Invoice.COLLECTION_METHOD
  currency?: string
  discount?: InvoiceDiscount
  partialPayment?: number
  nextPaymentAttempt?: ModelDate
  autoAdvance?: boolean
  defaultPaymentMethod?: string
  transactionId?: string
  customer?: {
    name?: PatientNameSchema,
    email?: string,
    address?: AddressSchema,
    phone?: string,
    taxExempt?: boolean,
  }
  statusTransitions?: StatusTransitions[]
  template?: InvoiceTemplate
  estimate?: EstimateSchema
}

export class InvoiceModel extends BaseModel {
  paid: boolean
  patientId: string
  appointmentId?: string
  lineItems: InvoiceLineItem[]
  status: Invoice.STATUS
  paymentOption?: Invoice.PAYMENT_OPTION
  paymentProvider?: PaymentProvider
  plan?: InvoicePlan
  cardId?: string
  amountDue: number
  amountPaid: number
  amountRemaining: number
  attemptCount: number
  subtotal: number
  tax: number
  taxAmount: number
  taxAmounts: InvoiceTaxAmount[]
  feeDue?: number
  feePaid?: number
  feeRemaining?: number
  total: number
  currency: string
  discount?: InvoiceDiscount
  partialPayment?: number
  statusTransitions: StatusTransitions[]
  template: InvoiceTemplate
  estimate: EstimateSchema
  collectionMethod: Invoice.COLLECTION_METHOD

  constructor(data: InvoiceData) {
    super(data)

    if (data) {
      this.paid = data.paid || false
      this.patientId = data.patientId
      this.appointmentId = data.appointmentId
      this.lineItems = data.lineItems || []
      this.status = data.status || Invoice.STATUS.DRAFT
      this.paymentOption = data.paymentOption
      this.paymentProvider = data.paymentProvider
      this.plan = data.plan
      this.cardId = data.cardId
      this.amountDue = data.amountDue
      this.amountPaid = data.amountPaid
      this.amountRemaining = data.amountRemaining
      this.attemptCount = data.attemptCount || 0
      this.subtotal = data.subtotal
      this.tax = data.tax
      this.taxAmount = data.taxAmount
      this.taxAmounts = data.taxAmounts || []
      this.feeDue = data.feeDue
      this.feePaid = data.feePaid
      this.feeRemaining = data.feeRemaining
      this.total = data.total
      this.currency = data.currency
      this.discount = data.discount
      this.estimate = data.estimate
      this.statusTransitions = data.statusTransitions
      this.template = data.template || {
        due: 30,
      }
      this.collectionMethod = data.collectionMethod
      this.partialPayment = data.partialPayment
    }
  }

  get text(): string {
    return `Invoice ${this.id || ''}`
  }

  get description(): string {
    if (this.lineItems && this.lineItems.length > 0) {
      return this.lineItems[0].description
    }

    return ''
  }

  get confirmedAtTime(): any {
    if (this.statusTransitions['C']) {
      return this.statusTransitions['C'].at
    }

    return ''
  }

  get paymentOptionDisplay(): string {
    // TODO use translate here
    switch (this.paymentOption) {
      case Invoice.PAYMENT_OPTION.FULL: return 'Pay in Full'
      case Invoice.PAYMENT_OPTION.PLAN: return 'Payment Plan'
      case Invoice.PAYMENT_OPTION.PARTIAL: return 'Partial Payment'
      default: return ''
    }
  }

  get date() {
    return this.updatedAt ? this.updatedAt.format('LL') : ''
  }

  get nextMonthlyPayment() {
    if (this.plan && this.plan.startAt) {
      const day = moment().date()
      const startAt = moment(this.plan.startAt)
      const endAt = moment(this.plan.endAt)
      const dayPlan = startAt.date()
      // If it finished
      if (endAt.diff(moment()) < 0) {
        return
      }

      if (day < dayPlan) {
        return moment().date(dayPlan).format()
      } else {
        return moment().date(dayPlan).add(1, 'month').format()
      }
    }

    return
  }

  get exclusiveTaxes() {
    return this.taxAmounts
      .filter((t) => !t.inclusive)
  }

  get inclusiveTaxes() {
    return this.taxAmounts
      .filter((t) => t.inclusive)
  }

  toJSON() {
    const data = extend(super.toJSON(), {
      paid: this.paid,
      patientId: this.patientId,
      appointmentId: this.appointmentId,
      lineItems: this.lineItems.map((lineItem) => {
        const item = cloneDeep(lineItem)
        // if (lineItem.taxRates && lineItem.taxRates.length > 0) {
        //   item.taxRates = lineItem.taxRates.map()
        // }
        return item
      }),
      status: this.status,
      paymentOption: this.paymentOption,
      paymentProvider: this.paymentProvider,
      plan: this.plan,
      amountDue: this.amountDue,
      amountPaid: this.amountPaid,
      amountRemaining: this.amountRemaining,
      attemptCount: this.attemptCount || 0,
      subtotal: this.subtotal,
      tax: this.tax,
      taxAmount: this.taxAmount,
      taxAmounts: this.taxAmounts.map((taxAmount) => {
        if (taxAmount.taxRate) {
          taxAmount = cloneDeep(taxAmount)
          taxAmount.taxRateId = taxAmount.taxRate.id
          unset(taxAmount, 'taxRate')
        }
        return taxAmount
      }),
      feeDue: this.feeDue,
      feePaid: this.feePaid,
      feeRemaining: this.feeRemaining,
      total: this.total,
      currency: this.currency,
      estimate: this.estimate,
      template: this.template,
      collectionMethod: this.collectionMethod,
      updatedAt: this.updatedAt.toISOString(),
    })

    return data
  }
}

export namespace Invoice {
  export enum STATUS {
    DRAFT = 'D',
    VOID = 'V',
    CONFIRMED = 'C',
    UNCOLLECTABLE = 'U',
    PAYING = 'Y',
    PARTIAL = 'S',
    PAID = 'P',
  }

  export enum PAYMENT_OPTION {
    PLAN = 'P',
    PARTIAL = 'PARTIAL',
    FULL = 'F',
  }

  export enum COLLECTION_METHOD {
    UPCOMING = 'U', // wait until the patient's appointment
    CHARGE = 'C', // charge the patient using a saved payment method
    SEND = 'S', // send to the patient immediately
  }
}
