import { SelectOption } from '@mmx/shared'

/**
 * @see http://hl7.org/fhir/v2/0001/index.html
 */
export const Sexes: SelectOption<string>[] = [
  { value: 'F', viewValue: 'patient-edit.sex-types.female' },
  { value: 'M', viewValue: 'patient-edit.sex-types.male' },
  { value: 'O', viewValue: 'patient-edit.sex-types.other' },
]
