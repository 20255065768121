export enum WebsocketAction {
  ALERTS = 'alerts',
  APPOINTMENT_UPDATED = 'appointment.updated',
  DASHBOARD = 'dashboard',
  DASHBOARD_WEEK_VIEW = 'dashboard-week-view',
  ELIGIBILITY_INQUIRY_CONSOLE = 'eligibilities.pullFromConsole',
  ELIGIBILITY_INQUIRY_PATIENT_PORTAL = 'eligibilities.pullFromPatientPortal',
  EXPORT = 'export',
  IMPORT = 'import',
  LOCK = 'lock',
  MESSAGING_NEW_MESSAGE = 'new-message',
  MESSAGING_TYPING = 'typing',
  NOTE = 'note',
  PATIENT_RESULTS = 'patient.results',
  PATIENT_RETRIEVE = 'patient.retrieve',
  PATIENT_SEARCH = 'patient.search',
  PRIOR_AUTHORIZATION_INDEX = 'prior-authorization.index',
  PRIOR_AUTHORIZATION_SUBMIT = 'prior-authorization.submit',
  PROCEDURES_ESTIMATE = 'procedures.estimate',
  UNLOCK = 'unlock',
  VALIDATE_AVAILABLE_TIMES = 'scheduling.available-times.validate',
  WORKLIST_AUTOMATIONS = 'worklist.automations',
  WORKLIST_BUILD = 'worklist.build',
  WORKLIST_DELETE = 'worklist.delete',
  WORKLIST_PREVIEW = 'worklist.preview',
  WORKLIST_UPLOAD_FILES = 'worklist.upload-files',
  WORKLIST_MOVE_FILE = 'worklist.move-item-file',
  TEXTRACT_FILE = 'textract.file',
}
