import { BaseModel, BaseModelData } from '@mmx/shared'

export interface ClinicianData extends BaseModelData {
  name: string
  email: string
  title: string
  role: Clinician.ROLES
}

export class ClinicianModel extends BaseModel {
  name: string
  email: string
  title: string
  role: Clinician.ROLES

  constructor(data: ClinicianData) {
    super(data)
    this.name = data.name
    this.email = data.email
    this.title = data.title
    this.role = data.role
  }
}

export namespace Clinician {
  export enum ROLES {
    UNRESTRICTED = 'U',
    SCHEDULER = 'A',
  }
}
