import { BaseModel, BaseModelData } from '@mmx/shared'
import { filter, get, includes } from 'lodash'

export interface EligibilityData extends BaseModelData {
  patientId: string
  appointmentId: string
  source: string
  providerId: string
  carrierName?: string
  valid: boolean
  active: boolean
  // payload: any
  data: any
  meta: any
}

export class Eligibility extends BaseModel {
  patientId: string
  appointmentId: string
  source: string // enum
  providerId: string // patientpal insurance carrier id
  valid: boolean
  active: boolean
  // payload: any
  data: any
  meta: any
  pcp: any

  private _carrierName: string // patientpal insurance carrier name

  constructor(data: EligibilityData) {
    super(data)
    this.patientId = data.patientId
    this.appointmentId = data.appointmentId
    this.source = data.source
    this.providerId = data.providerId
    this._carrierName = data.carrierName
    this.valid = data.valid
    this.active = data.active
    // this.payload = data.payload
    this.data = data.data
    this.meta = data.meta
  }

  get text(): string {
    return this.carrierName + ' ' + this.memberId
  }

  get name(): string {
    return this.date // TODO: this should show something like 'Tufts 0071234'
  }

  get memberId(): string {
    const memberId = get(this.data, 'plan.memberId')

    if (!memberId) {
      return 'common.none'
    }
    return memberId
  }

  get carrier(): string {
    return this.providerId
  }

  get carrierName(): string {
    return this._carrierName || this.providerId
  }

  get groupNumber(): string {
    return get(this, 'data.plan.groupNumber')
  }

  get good(): boolean {
    return this.valid && this.active
  }

  getCoinsuranceFor(serviceCode: string, inNetwork = true): number | null {
    const coinsurances = filter(this.data.coverage.coinsurance, (coinsurance) => {
      if (coinsurance.in_plan_network !== 'yes' && inNetwork) {
        return false
      }

      return includes(coinsurance.service_type_codes, serviceCode)
    })

    return get(coinsurances, '[0].benefit_percent')
  }

  getCopaymentFor(serviceCode: string, inNetwork = true): number | null {
    const copayments = filter(this.data.coverage.copay, (copay) => {
      if (copay.in_plan_network !== 'yes' && inNetwork) {
        return false
      }

      return includes(copay.service_type_codes, serviceCode)
    })

    const amount = get(copayments, '[0].copayment.amount')

    if (amount) {
      return parseFloat(amount)
    } else {
      return 0
    }
  }

  getInNetworkLabel(inPlanNetwork: string) {
    switch (inPlanNetwork) {
      case 'not_applicable': return 'All Providers (see messages)'
      case 'yes': return 'In Network'
      case 'no': return 'Out Of Network'
      default: return inPlanNetwork
    }
  }

  get date() {
    return this.updatedAt ? this.updatedAt.format('LL') : ''
  }

  get datetime() {
    return this.updatedAt ? this.updatedAt.format('LLL') : ''
  }

  get coverage_level() {
    return this.data.coverage.level.replace(/_/g, ' ')
  }
}
