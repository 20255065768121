import { Injectable } from '@angular/core'
import { SwUpdate } from '@angular/service-worker'
import { filter } from 'rxjs/operators'

@Injectable({
  providedIn: 'root',
})
export class NotificationService {

  constructor(
    private swUpdate: SwUpdate,
  ) {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.versionUpdates.pipe(
        filter(event => event.type === 'VERSION_READY'),
      ).subscribe(() => {
        this.actionBar()
      })
      // Every 15min we check if there is a new version
      setInterval(() => {
        this.swUpdate.checkForUpdate()
      }, 15 * 60 * 1000)
    }
  }

  actionBar() {
    this.swUpdate.activateUpdate().then(() => {
      let interval: any
      const label = `An update for PatientPal is available. Refreshing in 30 seconds…`
      const container = document.createElement('div')
      container.classList.add('action-bar-container')
      const bar = document.createElement('div')
      bar.classList.add('action-bar')
      const labelEl = document.createElement('span')
      labelEl.innerText = label
      bar.appendChild(labelEl)
      const skipBtn = document.createElement('button')
      skipBtn.classList.add(
        'mdc-button',
        'mat-mdc-button',
        'mat-accent',
        'mat-mdc-button-base',
      )
      skipBtn.innerText = 'Remind me in 5 minutes'
      skipBtn.addEventListener('click', () => {
        container.remove()
        clearInterval(interval)
        setTimeout(() => this.actionBar(), 5 * 60 * 1000)
      })
      bar.appendChild(skipBtn)
      const refreshNow = document.createElement('button')
      refreshNow.classList.add(
        'mdc-button',
        'mat-mdc-button',
        'mat-primary',
        'mat-mdc-button-base',
      )
      refreshNow.innerText = 'Refresh now'
      refreshNow.addEventListener('click', () => window.location.reload())
      bar.appendChild(refreshNow)
      container.appendChild(bar)
      document.body.appendChild(container)
      let countdown = 30

      interval = setInterval(() => {
        countdown--

        if (countdown <= 0) {
          window.location.reload()
        } else {
          labelEl.innerText = `An update for PatientPal is available. Refreshing in ${countdown} seconds…`
        }
      }, 1000)
    })
  }
}
