import { HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Consent, ConsentData, Facility, ILeg, IQuestion, RestService } from '@mmx/shared'
import { map as _map, sortBy } from 'lodash'
import { BehaviorSubject, Observable, of, throwError } from 'rxjs'
import { catchError, map } from 'rxjs/operators'

import { environment } from '../../../environments/environment'
import { NOT_FOUND, PAYMENT_REQUIRED } from '../../shared/data/http-status-codes'
import {
  Clinic,
  ClinicData,
} from '../../shared/models'
import { WebsiteContent } from '../../shared/models/website-content.interface'

@Injectable({
  providedIn: 'root',
})
export class ClinicService {
  static clinic: Clinic
  changes: Observable<Clinic>

  private subject = new BehaviorSubject<Clinic>(null)

  get clinic() {
    return ClinicService.clinic
  }

  get clinicId() {
    if (ClinicService.clinic) {
      return ClinicService.clinic.id
    }
  }

  constructor(protected restService: RestService) {
    this.changes = this.subject.asObservable()
  }

  loadIfNotLoaded() {
    if (ClinicService.clinic) {
      return of(ClinicService.clinic)
    } else {
      return this.load()
    }
  }

  load(): Observable<Clinic | undefined> {
    const host = environment['clinicId'] || location.hostname
    const route = `/clinics/${host}`

    return this.restService.get<ClinicData>(route).pipe(
      map(result => {
        ClinicService.clinic = new Clinic(result.data)
        this.subject.next(ClinicService.clinic)
        return ClinicService.clinic
      }),
      catchError((err: HttpErrorResponse) => {
        // if the clinic request returned a 404, then show an error to the user
        if (err.status === NOT_FOUND) {
          window.location.href = 'https://www.patientpal.com/login.html?clinic-not-found=1'
          return of(null)
        } else if (err.status === PAYMENT_REQUIRED) {
          alert(err.error || 'This website has been disabled.')
          return throwError(err)
        } else {
          return throwError(err)
        }
      }),
    )
  }

  getWebsiteContent(): Observable<WebsiteContent> {
    const content: WebsiteContent = {
      // jumbotron: {
      //   image: 1,
      //   tagline: 'Medical Services <span>That You Can Trust</span>',
      //   description: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit sed diam nonummy nibh euismod.',
      // },
      // about: `We're always making things better for someone`,
    }
    return of(content)
  }

  getQuestions(params: {
    appointment: string,
    intake?: string,
    section?: string,
  }): Observable<IQuestion[]> {
    const httpParams = new HttpParams({ fromObject: params as any })
    const route = `/clinics/${this.clinicId}/questions`
    return this.restService.get<IQuestion[]>(route, httpParams).pipe(
      map((resp) => resp.data),
    )
  }

  getIntakeSections(params?: {
    appointment?: string,
    intake?: string,
    includeHidden?: 'Y',
  }): Observable<ILeg[]> {
    const route = `/clinics/${this.clinicId}/sections`
    const httpParams = new HttpParams({ fromObject: params })
    return this.restService.get<ILeg[]>(route, httpParams).pipe(
      map((resp) => resp.data),
    )
  }

  getConsents(params?: {
      type?: string,
      intake?: string,
      appointment?: string,
    }) {
    const httpParams = new HttpParams({ fromObject: params })
    const route = `/clinics/${this.clinicId}/consents`

    return this.restService.get<ConsentData[]>(route, httpParams).pipe(map(resp => {
      if (resp.success) {
        return sortBy(_map(resp.data, (consentData) => new Consent(consentData)), 'order')
      } else {
        return []
      }
    }))
  }

  getLocations() {
    const route = `/clinics/${this.clinicId}/locations`
    return this.restService.get<Facility[]>(route).pipe(
      map(response => {
        return _map(response.data, (location) => new Facility(location))
      }),
    )
  }

  getTranslations(lang: string) {
    const route = `/clinics/${this.clinicId}/i18n`
    const params = new HttpParams({ fromObject: { lang } })
    return this.restService.get<{ [key: string]: string }>(route, params).pipe(
      map(response => {
        return response
      }),
    )
  }

  getMessageSubscription(id: string) {
    const requestOptions = {
      headers: new HttpHeaders({ 'WWW-Clinic-ID': this.clinic.id }),
    }

    const route = `/patient/message-subscription/${id}`
    return this.restService.get(route, null, requestOptions).pipe(
      map(result => {
        return result.data
      }))
  }

  updateMessageSubscription(fields: any) {
    const requestOptions = {
      headers: new HttpHeaders({ 'WWW-Clinic-ID': this.clinic.id }),
    }

    const route = `/patient/message-subscription/${fields.id}`
    return this.restService.put<any>(route, { 'subscription': fields }, requestOptions).pipe(
      map(result => {
        return result.data
      }))
  }

  createPaymentSetupIntent(patientId?: string) {
    return this.restService.post<string>(
      '/payments/setup-intent',
      {
        clinicId: this.clinicId,
        patientId,
      },
    )
      .pipe(
        map((response) => response.data),
      )
  }
}
