import { BaseModel, BaseModelData, ShoppableServiceLabel } from '@mmx/shared'

import { ProductData, ProductModel } from './product.model'

export interface ShoppableServiceData extends BaseModelData {
  clinicId: string
  name: string
  procedureName: string
  facilityIds: string[]
  resourceIds: string[]
  products: string[]
  productList?: ProductData[]
  labels?: ShoppableServiceLabel[]
  labelIds?: string[]
  eid?: string
  patientType: ShoppableService.PATIENT_TYPE
  status: ShoppableService.STATUS
  notes?: string
  code?: string
}

export class ShoppableServiceModel extends BaseModel {
  clinicId: string
  name: string
  procedureName: string
  facilityIds: string[]
  resourceIds: string[]
  products: string[]
  productList?: ProductModel[]
  labels?: ShoppableServiceLabel[]
  labelIds?: string[]
  eid: string
  code?: string
  patientType: ShoppableService.PATIENT_TYPE
  status: ShoppableService.STATUS
  notes: string

  constructor(data: ShoppableServiceData) {
    super(data)

    this.clinicId = data.clinicId
    this.name = data.name
    this.procedureName = data.procedureName
    this.eid = data.eid
    this.facilityIds = data.facilityIds
    this.resourceIds = data.resourceIds || []
    this.products = data.products
    this.labels = data.labels
    this.labelIds = data.labelIds
    this.patientType = data.patientType
    this.status = data.status
    this.code = data.code
    this.notes = data.notes

    if (data.productList) {
      this.productList = data.productList.map((product) => new ProductModel(product))
    }
  }
}

export namespace ShoppableService {
  export enum STATUS {
    ACTIVE = 'A',
    INACTIVE = 'I',
  }
  export enum PATIENT_TYPE {
    IN_PATIENT = 'IP',
    OUT_PATIENT = 'OP',
  }
}
