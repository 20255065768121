import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FlexLayoutModule } from '@angular/flex-layout'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'

import { MMXIconComponent } from './mmx-icon.component'

const declarations = [
  MMXIconComponent,
]

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatProgressSpinnerModule,
  ],
  exports: [
    ...declarations,
  ],
  declarations: [
    ...declarations,
  ],
})
export class MMXIconModule { }
